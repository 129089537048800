.top-section {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  height: calc(var(--size) * 5);
}
.top-section div:first-child.margin-bottom {
  margin-top: calc(var(--margin) * 0.5);
  flex: 1;
}
.top-section .pos_right {
  display: flex;
}
.top-section .pos_right > div {
  max-height: calc(var(--size) * 4);
  border-color: transparent;
  border-radius: calc(var(--size) * 2);
  box-shadow: 0 calc(0.5 * var(--size)) calc(1.5 * var(--size)) 0
    rgba(var(--black-rgb), 0.1);
}
.top-section .pos_right > div .default.text {
  color: var(--grey-33);
  margin-top: calc(var(--margin) * 0.8);
}
.top-section .pos_right > div:first-child {
  flex: 2;
}
.top-section .pos_right > div:last-child {
  flex: 3;
  margin-left: calc(var(--margin) * 0.5);
  box-shadow: 0 0 0 0 transparent;
}
.top-section .pos_right div[role="combobox"] {
  overflow-y: auto;
}
