.login-container {
  height: calc(100vh - calc(var(--size) * 10));
  display: grid;
  grid-template-rows: 3fr 5fr 75px;
  max-width: none;
  padding: 0;
}

.login__quote {
  padding: 0 20px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.login__quote__title {
  padding-bottom: calc(var(--size) * 0.7);
  text-align: center;
  font-size: var(--font-XL);
}
.login__quote__by {
  text-align: center;
  font-style: italic;
  font-size: var(--font-XS);
}

.login__form {
  margin: 0 auto;
  width: 600px;
}
@media print, screen and (max-width: 800px) {
  .login__form {
    width: 95%;
    padding: 0 20px;
  }
}

.login-container form label {
  margin-bottom: var(--margin);
}

.login__footer {
  background: var(--grey-f7);
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: calc(var(--padding) * 2) calc(var(--padding) * 3);
  justify-content: space-between;
}

/* Input box*/
.login__input input {
  border-radius: calc(var(--size) * 3) !important;
  position: relative;
}
.login__input input:-webkit-autofill,
.login__input input:-webkit-autofill:hover,
.login__input input:-webkit-autofill:focus {
  border: var(--border) solid var(--grey-f7) !important;
  -webkit-text-fill-color: black !important;
  background: var(--grey-f7) !important;
  transition: background-color 5000s ease-in-out 0s;
}
.login__input :after {
  position: absolute;
  display: block;
  z-index: 0;
  background-color: rgba(var(--black-rgb), 0.1);
  width: 100%;
  height: calc(var(--size) * 4);
  border-radius: calc(var(--size) * 3);
  pointer-events: none;
}
