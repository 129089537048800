.relative,
.position-relative {
  position: relative;
}
.absolute,
.position-absolute {
  position: absolute;
}

.top__0 {
  top: 0;
}
.top__80p {
  top: 80%;
}
.top__75p {
  top: 75%;
}
.right__0 {
  right: 0;
}
.bottom__0 {
  bottom: 0;
}
.left__0 {
  left: 0;
}

.float-right {
  float: right !important;
}
.float-left {
  float: left !important;
}
