/* Legend table styling */

canvas.chartjs-render-monitor ~ .chart-legend {
  width: 100%;
  padding-left: 30px;
  display: flex;
  justify-content: center;
  font-size: var(--font-XS);
  user-select: none;
}

.chart-legend .legend-color {
  display: inline-block;
  width: 20px;
  height: 5px;
  margin: 0 10px;
}

canvas.chartjs-render-monitor ~ .chart-legend table {
  width: 100%;
  border-collapse: collapse;
}

canvas.chartjs-render-monitor ~ .chart-legend td {
  padding: 0;
  margin: 0;
  line-height: calc(2 * var(--font-XS));
  height: initial;
  width: 8%;
  text-align: right;
}

canvas.chartjs-render-monitor ~ .chart-legend td:nth-child(1) {
  width: 68%;
  text-align: left;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

canvas.chartjs-render-monitor ~ .chart-legend tr:first-of-type td {
  text-align: right;
}

canvas.chartjs-render-monitor ~ .chart-legend tr {
  cursor: pointer;
}

canvas.chartjs-render-monitor ~ .chart-legend tr:not(.visible) td {
  color: var(--grey-cc);
}

canvas.chartjs-render-monitor
  ~ .chart-legend
  tr:nth-of-type(2n + 1):not(:first-of-type) {
  background-color: var(--grey-f7);
}

/* Tooltip styling */

canvas.chartjs-render-monitor ~ .chart-tooltip {
  opacity: 1;
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;
  position: absolute;
  z-index: 5;
  pointer-events: none;
  white-space: nowrap;
}

canvas.chartjs-render-monitor ~ .chart-tooltip table {
  border-collapse: collapse;
}

canvas.chartjs-render-monitor ~ .chart-tooltip td {
  padding: 0;
  margin: 0;
  line-height: calc(1.5 * var(--font-XS));
  font-size: var(--font-XS);
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  height: initial;
  width: initial;
}

canvas.chartjs-render-monitor ~ .chart-tooltip td div {
  vertical-align: middle;
}

canvas.chartjs-render-monitor ~ .chart-tooltip td:first-child {
  padding-right: 10px;
}

canvas.chartjs-render-monitor ~ .chart-tooltip .legend {
  display: inline-block;
  width: 10px;
  height: 3px;
  margin: 0 1px;
}

/* Node Select */

.node-select.ui.selection.dropdown {
  padding-right: calc(2.1em + 20px) !important;
}

.node-select.ui.selection.dropdown .text {
  font-family: monospace;
  font-size: var(--font-XS);
}

.node-select.ui.selection.dropdown .menu {
}

.node-select.ui.selection.dropdown .group {
  padding-top: calc(var(--size) * 1);
  padding-bottom: calc(var(--size) * 0.5);
  padding-left: 10px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: none;
}

.node-select.ui.selection.dropdown .menu > .item {
  padding-left: calc(var(--padding) * 3) !important;
  font-family: monospace;
  font-size: var(--font-XS);
}

.node-select.ui.selection.dropdown.mark-selected .menu > .item.selected:before {
  content: "";
  display: inline-block;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: var(--green);
  margin-left: -15px;
  margin-right: 10px;
  vertical-align: middle;
  line-height: calc(var(--size) * 5);
}
