/* Only way to put the QuickView popup menu on top of Modals and other Popups*/
body > div:not(#root):not(.ui) {
  z-index: 2000 !important;
}

.quick-view__wrapper {
  display: inline-block;
}
.ui.popup.quick-view__wrapper {
  padding: 0;
  border: 0 none;
  border-radius: calc(var(--size) * 2.5);
  overflow: hidden;
  position: relative;
  z-index: 5;
}

/* The wrapper around QuickView Trigger and Contents */
.moreData-table .quick-view__wrapper,
.more-data .react-table .quick-view__wrapper,
.simple-table .quick-view__wrapper {
  padding: calc(var(--size) * 0.5) 0 0 0 !important;
}

/* Trigger */
.quick-view__trigger {
  cursor: pointer;
  display: flex;
  max-width: 100%;
  white-space: nowrap;
  line-height: calc(var(--size) * 1.4);
  height: calc(var(--size) * 2.5);
  overflow: hidden;
  align-items: baseline;
  text-overflow: ellipsis;
}
.more-data .quick-view__trigger {
  justify-content: center;
}
.more-data .simple-table .quick-view__trigger,
.more-data td:first-child .quick-view__trigger,
.more-data .justify-left .quick-view__trigger {
  justify-content: left;
}
.quick-view__trigger:hover * {
  color: var(--orange) !important;
}

/* Trigger text */
.quick-view__trigger__span {
  white-space: normal;
  line-height: var(--quick-view-item);
  display: inline-block;
  overflow: hidden;
  overflow-wrap: anywhere;
  position: relative;
}
.quick-view__trigger__span:after {
  display: block;
  position: absolute;
  right: 0;
  bottom: calc(var(--size) * 3);
  width: calc(var(--size) * 3);
  height: calc(var(--quick-view-item) * 5);
  line-height: var(--quick-view-item);
  content: "....................................";
  text-align: center;
  word-break: break-word;
  letter-spacing: 0px;
  padding-left: var(--size);
  overflow: hidden;
  background: rgb(255, 255, 255);
  background: linear-gradient(90deg, rgba(255, 255, 255, 0.2) 0%, white 80%);
}
.more-data .quick-view__trigger__span {
  white-space: nowrap;
  text-overflow: ellipsis;
}

.quick-view {
  min-width: calc(var(--size) * 20);
  padding: 0;
  overflow: hidden;
}
.quick-view__item {
  cursor: pointer;
  padding: 0 calc(var(--padding) * 1.5);
  line-height: calc(var(--size) * 5);
  max-height: calc(var(--size) * 5);

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: calc(var(--size) * 30);
}
.quick-view__item:hover {
  background-color: var(--grey-ee);
}
.quick-view__item-text {
  padding-left: calc(var(--padding) * 0.5);
}
.quick-view__item-text-with-icon {
  padding-left: var(--padding);
}
.quick-view__item-icon.icon {
  font-size: var(--font-M);
  margin-left: calc(var(--margin) * 0.5);
  color: var(--grey-66);
}
.quick-view__divider {
  border-bottom: 1px solid var(--grey-ee);
  margin: var(--margin) 0;
  height: 0;
  cursor: default;
}
.override-popup {
  border: 0px solid !important;
  padding: var(--padding) !important;
}
