.signup-tab {
  position: fixed;
  top: 10vh;
  left: 10%;
  width: 80%;
  background-color: white;
  border-radius: calc(var(--size) * 2);
  box-shadow: 0 calc(0.5 * var(--size)) calc(1.5 * var(--size)) 0
    rgba(var(--black-rgb), 0.1);
  z-index: 600;
}

.signup-tab__header {
  display: flex;
  background-color: var(--grey-f7);
  text-align: center;
  border-radius: calc(var(--size) * 2) calc(var(--size) * 2) 0 0;
  overflow: hidden;
}
.signup-tab__header__item {
  padding: calc(var(--padding) * 1.5) var(--padding);
  color: var(--grey-66);
  flex: 1;
}
.signup-tab__header__item.active {
  background-color: var(--white);
}

.signup-tab__content {
  padding: calc(var(--padding) * 1.5) calc(var(--padding) * 2);
  color: var(--grey-66);
}
.signup-tab__box {
  padding: calc(var(--padding) * 2);
  margin-bottom: calc(var(--margin) * 3);
  border-radius: calc(var(--size) * 2.5);
  background-color: var(--grey-f7);
}
.signup-tab__box:hover {
  background: transparent;
  box-shadow: 0 0 var(--size) 0 rgba(var(--black-rgb), 0.1);
}
.signup-tab__box:last-child {
  margin-bottom: 0;
}

.signup__border {
  font-style: italic;
  color: var(--grey-cc);
  padding-left: var(--padding);
  width: 100%;
  height: calc(var(--size) * 2);
  border-bottom: var(--border) solid var(--grey-ee);
}

.user-attention {
  animation: user-attention 2s linear forwards;
}
.user-attention input {
  animation: user-attention--input 2s linear forwards;
}
