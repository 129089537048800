.padding-top-00 {
  padding-top: 0 !important;
}
.padding-top {
  padding-top: var(--padding) !important;
}
.padding-top-half {
  padding-top: calc(var(--padding) * 0.5) !important;
}
.padding-top-10 {
  padding-top: var(--padding) !important;
}
.padding-top-20 {
  padding-top: calc(var(--padding) * 2) !important;
}
.padding-top-30 {
  padding-top: calc(var(--padding) * 3) !important;
}
.padding-top-40 {
  padding-top: calc(var(--padding) * 4) !important;
}
.padding-top-50 {
  padding-top: calc(var(--padding) * 5) !important;
}

.padding-right-00 {
  padding-right: 0 !important;
}
.padding-right {
  padding-right: var(--padding) !important;
}
.padding-right-half {
  padding-right: calc(var(--padding) * 0.5) !important;
}
.padding-right-10 {
  padding-right: var(--padding) !important;
}
.padding-right-20 {
  padding-right: calc(var(--padding) * 2) !important;
}
.padding-right-30 {
  padding-right: calc(var(--padding) * 3) !important;
}
.padding-right-40 {
  padding-right: calc(var(--padding) * 4) !important;
}
.padding-right-50 {
  padding-right: calc(var(--padding) * 5) !important;
}

.padding-bottom-00 {
  padding-bottom: 0 !important;
}
.padding-bottom {
  padding-bottom: var(--padding) !important;
}
.padding-bottom-half {
  padding-bottom: calc(var(--padding) * 0.5) !important;
}
.padding-bottom-10 {
  padding-bottom: var(--padding) !important;
}
.padding-bottom-20 {
  padding-bottom: calc(var(--padding) * 2) !important;
}
.padding-bottom-30 {
  padding-bottom: calc(var(--padding) * 3) !important;
}
.padding-bottom-40 {
  padding-bottom: calc(var(--padding) * 4) !important;
}
.padding-bottom-50 {
  padding-bottom: calc(var(--padding) * 5) !important;
}
.padding-bottom-100 {
  padding-bottom: calc(var(--padding) * 10) !important;
}

.padding-left-00 {
  padding-left: 0 !important;
}
.padding-left {
  padding-left: var(--padding) !important;
}
.padding-left-half {
  padding-left: calc(var(--padding) * 0.5) !important;
}
.padding-left-10 {
  padding-left: var(--padding) !important;
}
.padding-left-15 {
  padding-left: calc(var(--padding) * 1.5) !important;
}
.padding-left-20 {
  padding-left: calc(var(--padding) * 2) !important;
}
.padding-left-30 {
  padding-left: calc(var(--padding) * 3) !important;
}
.padding-left-40 {
  padding-left: calc(var(--padding) * 4) !important;
}
.padding-left-50 {
  padding-left: calc(var(--padding) * 5) !important;
}

.no-padding {
  padding: 0 !important;
}
