/* The Modal Wrapper */
.ui.modals .modal {
  border-radius: calc(var(--size) * 1.5) !important;
  overflow: hidden;
  box-shadow: 0 0 calc(2 * var(--size)) 0 rgba(var(--black-rgb), 0.2);
}

/* The Modal Header */
.ui.modals .modal > .header {
  font-family: var(--font-family);
  background-color: var(--white);
  display: flex;
  border: 0 none;
  padding: calc(var(--padding) * 1.5) calc(var(--padding) * 2);
  font-size: var(--font-L);
  align-items: center;
}
.ui.modals .modal > .header > .icon {
  margin-right: calc(var(--margin) * 0.5);
  font-size: var(--font-XL);
}
.ui.modals .modal > .header > .icon + .content {
  font-family: var(--font-family);
  font-weight: normal;
  padding-left: 0;
  padding-top: calc(var(--size) * 0.1);
}
.ui.modals .modal > .header-center {
  justify-content: center;
  align-items: center;
  color: black;
  font-weight: normal;
}

/* The Modal Content */
.ui.modals .modal > .content {
  background-color: var(--grey-fc);
  box-shadow:
    0 calc(-1.5 * var(--size)) calc(1.5 * var(--size)) calc(-1.5 * var(--size))
      rgba(var(--black-rgb), 0.1) inset,
    0 calc(1.5 * var(--size)) calc(1.5 * var(--size)) calc(-1.5 * var(--size))
      rgba(var(--black-rgb), 0.1) inset;
  max-height: calc(100vh - calc(var(--size) * 20));
  overflow: auto;
}

/* The Modal Actions */
.ui.modals .modal .actions {
  border: 0 none;
  background-color: var(--white);
  padding: calc(var(--padding) * 1.5) calc(var(--padding) * 3);
}
.ui.modals .modal .actions:after {
  clear: both;
  display: block;
  content: "";
}

/* Fullscreen Modal */
.ui.modals .ui.fullscreen {
  left: 2em !important;
  margin: 0 !important;
}
.ui.modals .ui.full-height {
  height: calc(100% - 4em);
}
.ui.modals .ui.fullscreen .header {
  color: black;
  display: flex;
  align-items: center;
  font-weight: normal;
}
.ui.modals .ui.fullscreen .content {
  height: calc(100% - 4em);
  max-height: none;
  overflow: hidden;
  padding: calc(var(--padding) * 0.2) calc(var(--padding) * 2);
}

/* IFrame in Fullscreen Modal */
.ui.modals .ui.fullscreen iframe {
  width: 100%;
  height: 100%;
}
