.button {
  height: var(--button-height);
  font-size: var(--font-S);
  overflow: hidden;
  border-radius: calc(var(--size) * 2.5);
  padding: var(--padding) calc(var(--padding) * 2);
  text-transform: unset;
  font-family: inherit;
  display: flex;
  transition: all 0.3s;
  cursor: pointer;
  align-items: center;
}
.button:hover {
  transform: translateY(calc(-0.2 * var(--size)));
}

/* Disabled */
.button--disabled {
  opacity: 0.5;
  box-shadow: 0 0 0 0 transparent;
  pointer-events: all;
  cursor: not-allowed;
}
.button--disabled:hover {
  transform: translateY(0);
}

.button--small {
  padding: var(--padding);
  height: calc(var(--button-height) * 0.82);
}

.button--inline {
  display: inline-block;
  padding-left: calc(var(--padding) * 0.5);
  padding-right: calc(var(--padding) * 0.5);
  transition: none;
  color: var(--orange);
}
.button--no-transition {
  transition: none;
}
.button--no-transition:hover {
  transform: none;
}
.button--inline:hover,
.button--inline:focus {
  color: var(--blue);
}
