.separator {
  position: relative;
  padding-bottom: calc(var(--padding) * 4) !important;
  margin-bottom: calc(var(--margin) * 4);
}
.separator:after {
  content: "";
  position: absolute;
  left: calc(-1 * var(--row-height));
  right: calc(-1 * var(--row-height));
  box-shadow: 0 calc(-0.2 * var(--size)) calc(0.4 * var(--size))
    calc(0.1 * var(--size)) rgba(var(--black-rgb), 0.05);
  bottom: calc(-1 * var(--size));
  border-top: var(--border) solid rgba(var(--black-rgb), 0.05);
}

.more-data .separator {
  padding-bottom: calc(var(--padding) * 2) !important;
}

.separator.separator--simple {
  border-top: var(--border) solid var(--grey-cc);
  padding-top: var(--padding) !important;
  padding-bottom: var(--padding) !important;
}
.separator.separator--simple:after {
  display: none;
}
