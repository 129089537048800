.fancy-header {
  position: relative;
  display: flex;
  align-items: center;
  position: fixed;
  width: var(--sliding-window);
  margin: calc(-1.5 * var(--row-height)) 0 0 calc(-4 * var(--margin));
  background: var(--grey-f7);
  height: var(--row-height);
  padding: 0 calc(var(--row-height) * 0.5);
  z-index: 10;
}
.fancy-header .knowledgebase-open-wrapper {
  flex: 1;
  text-align: end;
  padding-left: calc(var(--size) * 0.25);
}
.fancy-header__title {
  font-size: var(--font-L);
}
.fancy-header__region,
.fancy-header__subtitle {
  margin: 0 0 0 calc(var(--margin) * 0.5);
  border-left: var(--border) dotted var(--grey-cc);
  padding: 0 0 0 calc(var(--padding) * 0.5);
}
.fancy-header__subtitle {
  text-overflow: ellipsis;
  overflow: hidden;
  text-wrap: nowrap;
  flex: 1;
}
