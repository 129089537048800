.curve-table .ui.celled.table tr :is(td, th) {
  border-color: var(--border-color);
}
.curve-table {
  border-radius: calc(var(--size) * 1.5);
  overflow: hidden;
  border: var(--border) solid var(--grey-ee);
  margin: var(--margin) 0;
  box-shadow: 0 calc(0.5 * var(--size)) calc(0.5 * var(--size)) 0
    rgba(var(--black-rgb), 0.1);
}
.curve-table table {
  border: var(--border) solid transparent !important;
  position: relative;
}

.curve-table table thead tr {
  display: flex;
}
.curve-table table thead th {
  background-color: var(--white) !important;
  line-height: calc(var(--size) * 1.5);
  height: calc(var(--size) * 5);
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  flex: 1;
  display: flex;
  align-items: center;
  text-overflow: ellipsis;
  overflow: hidden;
}
.curve-table table thead:after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  height: calc(var(--size) * 0.2);
  top: calc(var(--row-height) - 1);
  box-shadow: 0 calc(0.3 * var(--size)) calc(0.5 * var(--size)) 0
    rgba(var(--black-rgb), 0.15);
  background: rgba(0, 0, 0, 0.01);
}

.curve-table table tbody tr {
  display: flex;
}
.curve-table table tbody tr td {
  padding: 0 var(--padding) !important;
  flex: 1;
  max-width: none !important;
  display: flex;
  align-items: center;
  text-overflow: ellipsis;
  overflow: hidden;
  height: calc(var(--size) * 5);
  line-height: calc(var(--size) * 1.5);
  color: var(--grey-66);
}
.curve-table table tbody tr td.row_Icon {
  font-size: var(--font-L);
}
.curve-table table tbody tr td .checkbox.simple-radio {
  margin-bottom: 0;
}

/* This is to be added to the left most cells that only include a checkbox */
/* Sample of usage is ModifySecurityGroup component */
.curve-table--cell-shrink {
  flex: 0 !important;
  flex-basis: 60px !important;
}
.curve-table--cell-double {
  flex: 2 !important;
}
