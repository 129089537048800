.ui.checkbox.simple-radio {
  width: 100%;
  min-height: calc(var(--size) * 2);
  margin-bottom: var(--margin);
}
.ui.checkbox.simple-radio label {
  margin-left: 0;
  padding-left: calc(var(--padding) * 3);
}
.ui.checkbox.simple-radio label:before {
  background-color: var(--grey-ee);
  width: calc(var(--size) * 2);
  height: calc(var(--size) * 2);
  border-color: var(--grey-ee);
  top: calc(-0.3 * var(--size));
}

.ui.checkbox.simple-radio input:checked ~ label:before {
  background-color: var(--grey-ee);
  border: calc(7 * var(--border)) solid var(--orange);
}
.ui.checkbox.simple-radio input:checked ~ label:after {
  display: none;
}
