.chart-container {
  height: calc(var(--size) * 20);
}
.chart-container--big {
  height: calc(var(--size) * 35);
}
.chart-container + .warning-text {
  position: absolute;
  left: 0;
  width: 100%;
  top: 0;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 100px;
  pointer-events: none;
  color: var(--grey-80);
}
