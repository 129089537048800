.page-toolbar {
  height: var(--row-height);
  color: var(--grey-45);
  background: var(--grey-f7);
  width: 100%;
  padding: calc(0.5 * var(--padding)) var(--padding) calc(0.5 * var(--padding))
    calc(2 * var(--padding));

  display: flex;
  align-items: center;

  position: sticky;
  top: var(--row-height);
  z-index: 240;
}
.page-toolbar__buttons {
  margin-left: auto;
}
