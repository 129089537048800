.getting-started {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 5000;
  pointer-events: none;
}

.getting-started--backdrop {
  overflow: hidden;
  position: absolute;
  z-index: 2222;
  background-color: rgba(var(--black-rgb), 0.5);
  mix-blend-mode: hard-light;

  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
}
.getting-started--hight-light {
  border-radius: calc(var(--size) * 0.4);
  position: absolute;
  background-color: rgba(var(--white-rgb), 0.7);
  border: var(--border) solid rgba(var(--black-rgb), 0.3);
  opacity: 0.6;
  pointer-events: none;
}

.getting-started--current-tip {
  width: var(--feature-highlight-tip-size);
  background-color: var(--white);
  border-radius: calc(var(--size) * 1.5);
  box-shadow: 0 0 calc(2 * var(--size)) 0 rgba(var(--black-rgb), 0.2);
  pointer-events: all;
  position: fixed;
}

.getting-started__skip {
  cursor: pointer;
  position: absolute;
  right: calc(0.2 * var(--size));
  top: calc(0.2 * var(--size));
  width: calc(var(--size) * 2);
  height: calc(var(--size) * 2);
  font-size: 20px;
  color: var(--white);
  text-shadow: 0 0 calc(0.5 * var(--size)) var(--black);
  z-index: 10;
}

.getting-started__header {
  padding: calc(var(--padding) * 2) calc(var(--padding) * 3);
  font-weight: 700;
  display: flex;
}
.getting-started__content {
  padding: calc(var(--padding) * 2) calc(var(--padding) * 3)
    calc(var(--padding) * 3);
  min-height: calc(var(--size) * 10);
  max-height: calc(var(--size) * 60);
  box-shadow: 0 calc(-1.5 * var(--size)) calc(1.5 * var(--size))
    calc(-1.5 * var(--size)) rgba(var(--black-rgb), 0.2) inset;
}
.getting-started__actions {
  padding: calc(var(--padding) * 1.5) calc(var(--padding) * 3) var(--padding);
}

.getting-started__arrow i {
  position: absolute;
  left: 0;
  top: 0;
  width: calc(2 * var(--size));
  height: calc(2 * var(--size));
  color: white;
  text-shadow: 0 0 var(--size) var(--black);
}

.getting-started__arrow.beneath-after i {
  left: calc(-1.3 * var(--size));
  top: calc(-1.2 * var(--size));
  transform: rotate(-135deg);
}
.getting-started__arrow.beneath-before i {
  left: calc(100% - calc(0.8 * var(--size)));
  top: calc(-1.1 * var(--size));
  transform: rotate(75deg);
}

.getting-started__arrow.top-after i {
  left: calc(-1.8 * var(--size));
  top: calc(0.8 * var(--size));
  transform: rotate(-180deg);
}
.getting-started__arrow.top-before i {
  left: 100%;
  top: calc(0.8 * var(--size));
  transform: rotate(0deg);
}

.getting-started__arrow.bottom-after i {
  left: calc(-1.8 * var(--size));
  top: calc(0.8 * var(--size));
  transform: rotate(-180deg);
}
.getting-started__arrow.bottom-before i {
  left: 100%;
  top: calc(0.8 * var(--size));
  transform: rotate(0deg);
}

.getting-started__arrow.above-after i {
  left: calc(-1.3 * var(--size));
  top: calc(100% - calc(var(--size) * 0.8));
  transform: rotate(135deg);
}
.getting-started__arrow.above-before i {
  left: calc(100% - calc(0.8 * var(--size)));
  top: calc(100% - calc(0.8 * var(--size)));
  transform: rotate(45deg);
}
