#page-wrapper.with-delete-request {
  padding-top: calc(2 * var(--row-height));
}
#page-wrapper.with-delete-request .header-delete-request {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--white);
  background-color: var(--red-dark);
  height: var(--row-height);
}
#page-wrapper.with-delete-request .app-header {
  min-height: calc(2 * var(--row-height));
  flex-wrap: wrap;
}
#page-wrapper.with-delete-request .page-toolbar {
  top: calc(2 * var(--row-height));
}
#page-wrapper.with-delete-request .page-content .top-section {
  top: calc(3 * var(--row-height));
}
