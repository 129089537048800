.tag-list .tag {
  margin: 0 0 var(--margin);
}

.tag-list .tag .text {
  height: var(--button-height);
  line-height: var(--button-height);
  border-radius: calc(var(--size) * 2.5) 0 0 calc(var(--size) * 2.5);
  padding: 0 var(--padding) 0 calc(var(--padding) * 2);
  background: var(--grey-ee);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.tag-list .tag .icon {
  cursor: pointer;
  background: var(--grey-ee);
  height: var(--button-height);
  padding: calc(var(--padding) + 2px) calc(var(--padding) * 2.5) var(--padding)
    calc(var(--padding) * 1.5);
  border-radius: 0 calc(var(--size) * 2.5) calc(var(--size) * 2.5) 0;
  transition: background 0.3s;
}

.tag-list .tag .icon:hover {
  background-color: var(--grey-dd);
}
