.estimated-invoice {
  animation: animate-height 300ms ease-out 1;
  margin-bottom: calc(var(--margin) * 1.5);
  overflow: hidden;
}

/* Modifying classes */
.estimated-invoice--inner {
  width: 620px;
}
.estimated-invoice--rounded {
  box-shadow: 0 calc(0.6 * var(--size)) calc(1.2 * var(--size)) 0
    rgba(var(--black-rgb), 0.05);
  border-radius: calc(var(--size) * 1.2);
}

/* header */
.estimated-invoice__header {
  background-color: var(--green);
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: var(--padding) calc(var(--padding) * 2.5);
  height: var(--row-height);
}
.more-data .estimated-invoice__header {
  padding: var(--padding) calc(var(--padding) * 1.5);
}
.estimated-invoice__title {
  color: var(--white);
  margin: 0;
  padding: 0;
  border-bottom: calc(2 * var(--border)) solid transparent !important;
}
.estimated-invoice__price {
  color: var(--white);
  font-size: var(--font-S);
  margin: 0 var(--margin) 0 calc(1.5 * var(--margin));
  padding: 0;
}
.estimated-invoice__price span {
  border-bottom: calc(2 * var(--border)) solid var(--white) !important;
}
.estimated-invoice__trigger {
  color: var(--white);
  flex: 1;
  font-size: var(--font-XL);
  text-align: right;
  padding-right: 0;
}

/* The content part */
.estimated-invoice__body {
  max-height: 0;
  background-color: var(--green-light);
  padding: var(--padding) calc(var(--padding) * 1.5) var(--padding)
    calc(var(--padding) * 1.5);
  overflow: hidden;
}
.estimated-invoice__shrunk {
  max-height: 0;
  opacity: 0;
  padding-top: 0;
  padding-bottom: 0;
}
.estimated-invoice__expanded {
  padding-top: var(--padding);
  opacity: 1;
  max-height: calc(var(--size) * 50);
}
.estimated-invoice__expanded table tbody {
  background: transparent;
}
.estimated-invoice__expanded td:last-child {
  text-align: right;
}

.estimated-invoice--error .estimated-invoice__header {
  background: transparent;
}
