.ui.input {
  width: 100%;
}
.ui.input input {
  font-family: inherit;
  outline: 0;
}

.ui.input input[type="text"],
.ui.input input[type="number"],
.ui.input input[type="password"] {
  padding: calc(var(--padding) * 1.4);
  border-radius: calc(var(--size) * 2);
  height: calc(var(--size) * 4);
  background-color: var(--grey-ee);
  border: 0 none;
}

/* Inputs main classes similar to SelectBox*/
.ui.input.select-box input,
.ui.input.select-box textarea {
  background: var(--grey-f7) !important;
  border: 0 none;
  padding-left: calc(var(--padding) * 2);
  padding-right: calc(var(--padding) * 2);
}

.ui.input.select-box.error input {
  border: var(--red) var(--border) solid;
}

.ui.input.select-box.labeled {
  display: flex;
}
.ui.input.select-box.labeled .label {
  border-radius: 20px 0 0 20px;
  background: var(--grey-f7);
  border-right: 1px solid var(--grey-ee);
}
.ui.input.select-box.labeled .label[aria-expanded="true"] {
  border-radius: 20px 0 0 0;
}
.ui.input.select-box.labeled input {
  flex: 1;
}

/* Input with Error state */
.ui.input.error > input {
  color: var(--red-dark);
  border: var(--border) solid var(--red-dark);
}
.ui.input.error i {
  color: var(--red-dark);
  opacity: 0.8;
}

/* Input to filter main list */
.ui.input--main-page-filter {
  color: var(--grey-66);
  background-color: var(--grey-fc) !important;
}
.ui.input--main-page-filter input {
  color: var(--grey-66) !important;
  background-color: var(--grey-f7) !important;
  padding-left: calc(var(--padding) * 2);
  padding-right: calc(var(--padding) * 2);
}
.ui.input--main-page-filter input::-webkit-input-placeholder {
  color: var(--grey-45);
}
