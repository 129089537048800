.monitoring {
  align-items: center;
}
.monitoring__row {
  display: flex;
  width: 100%;
}

@media print, screen and (max-width: 800px) {
  .monitoring__row {
    display: block;
  }
  .monitoring__row > div {
    width: 100%;
    aspect-ratio: 3/2;
  }
}
@media print, screen and (min-width: 800px) and (max-width: 1600px) {
  .monitoring__row > div:nth-child(2n + 1) {
    flex: 2 !important;
    aspect-ratio: 5/2;
  }
  .monitoring__row > div:nth-child(2n) {
    flex: 1 !important;
    aspect-ratio: 5/2;
  }
}
@media print, screen and (min-width: 1600px) {
  .monitoring__row {
    width: 70%;
  }
  .monitoring__row > div:nth-child(2n + 1) {
    flex: 2 !important;
    aspect-ratio: 5/2;
  }
  .monitoring__row > div:nth-child(2n) {
    flex: 1 !important;
    aspect-ratio: 3/2;
  }
}
