.two-fa {
  display: flex;
  padding: 20px;
  flex-direction: column;
  border-radius: calc(var(--size) * 3);
  box-shadow: 0 calc(var(--size) * 0.5) calc(var(--size) * 2) 0
    rgba(var(--black-rgb), 0.1);
}
.two-fa__title {
  text-align: center;
  font-size: var(--font-XL);
  padding-top: var(--padding);
  padding-bottom: calc(var(--padding) * 2);
  border-bottom: 1px solid var(--grey-ee);
}
.two-fa__icons svg {
  width: 80px;
  margin-left: 10px;
  height: 80px;
  fill: var(--orange);
  border: 1px solid var(--grey-ee);
  padding: var(--padding);
  border-radius: var(--size);
}
