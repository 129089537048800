.circular-progress {
  position: absolute;
  z-index: -1;
  background: var(--white);
  margin-left: 0;
  width: calc(var(--size) * 3);
  height: calc(var(--size) * 3);
  overflow: hidden;
}
.circular-progress__pie {
  width: 50%;
  height: 100%;
  transform-origin: 100% 50%;
  position: absolute;
  background: var(--white);
  border-width: calc(3 * var(--border));
  border-style: solid;
}
.circular-progress__spinner {
  border-radius: 100% 0 0 100% / 50% 0 0 50%;
  z-index: 200;
  border-right: none;
  animation: rotate-clockwise 2s linear infinite;
}
.circular-progress__filler {
  border-radius: 0 100% 100% 0 / 0 50% 50% 0;
  left: 50%;
  opacity: 0;
  z-index: 100;
  animation: progress-opacity-halfway 2s steps(1, end) infinite reverse;
  border-left: none;
}
.circular-progress__mask {
  width: 50%;
  height: 100%;
  position: absolute;
  background: inherit;
  opacity: 1;
  z-index: 300;
  animation: progress-opacity-halfway 2s steps(1, end) infinite;
}

.circular-progress__text {
  position: absolute;
  margin-left: calc(3 * var(--size));
  z-index: -1;
  font-size: var(--font-XS);
  white-space: nowrap;
  overflow: hidden;
  max-width: calc(4 * var(--size));
}
.os-icon-holder ~ .circular-progress__text {
  margin-top: 5px;
  left: 0;
}
.circular-progress__text span {
  display: block;
  animation: progress-text 5s linear infinite;
}
.circular-progress__text span:hover {
  animation-play-state: paused;
}

.SERVERS_LIST .circular-progress {
  margin-top: calc(-0.1 * var(--size));
}
.SERVERS_LIST .circular-progress__text {
  margin-top: calc(var(--size) * 0.8);
}

.react-table .progress-wrapper,
.react-table .circular-progress,
.react-table .progress-text {
  display: none !important;
}
