.knowledgebase {
  position: fixed;
  width: var(--knowledge-base);
  display: none;
  z-index: 1000;
  top: var(--row-height);
  bottom: 0;
  right: 0;
}
.knowledgebase--visible {
  display: block;
}

.knowledgebase__animation {
  animation: slide-from-right 100ms linear forwards;
  z-index: 1000;
  position: absolute;
  left: 100%;
  top: 0;
  background: var(--white);
  height: 100%;
  box-shadow: 0 0 calc(2 * var(--size)) 0 rgba(var(--black-rgb), 0.2);
}

.knowledgebase__header {
  padding: calc(var(--padding) * 2);
  padding-right: var(--padding);
  background-color: var(--grey-f7);
  display: flex;
  height: var(--row-height);
  align-items: center;
  justify-content: space-between;
}
.knowledgebase__header span {
  font-size: var(--font-L);
}
.knowledgebase__header i {
  height: calc(var(--size) * 2);
  cursor: pointer;
}

.knowledgebase__content {
  width: var(--knowledge-base);
  padding: calc(var(--padding) * 2);
  color: var(--grey-45);
}

.knowledgebase__button {
  padding: 0;
}
.knowledgebase__button i.icon {
  height: calc(var(--size) * 3);
  width: calc(var(--size) * 3);
  line-height: calc(var(--size) * 3);
  color: var(--orange);
  font-size: var(--font-XL);
  transition: all 0.3s;
  /* shall be !important to override ui.header .icon:only-child on semantic css */
  margin: 0 !important;
}
.knowledgebase__button i:hover {
  transform: translateY(calc(-0.2 * var(--size)));
}
