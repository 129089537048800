.button--over-input {
  border-left: var(--border) solid var(--grey-ee);
  border-radius: 0 calc(var(--size) * 3) calc(var(--size) * 3) 0;
  background-color: var(--grey-ee);
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(var(--size) * 4);
  position: absolute;
  right: 0;
  width: calc(var(--size) * 4);
  transition: none;
}
.button--over-input:hover {
  transform: translateY(0);
}
