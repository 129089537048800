:root {
  --hamburger-icon-size: calc(var(--size) * 2.5);
}

.hamburger-wrapper {
  overflow: visible;
  width: 40px;
}

/* Overwrite padding bottom */
.hamburger-menu {
  padding-bottom: 0 !important;
  position: relative;
}

/* Closed state for hamburger menu */
.hamburger--closed {
  text-align: center;
  color: var(--grey-80);
  position: relative;
  border-radius: 50%;
  cursor: pointer;
}

/* Opened state for hamburger menu */
.hamburger--opened {
  position: absolute;
  border-radius: 0 var(--hamburger-icon-size) var(--hamburger-icon-size)
    var(--hamburger-icon-size);
  overflow: hidden;
  background-color: var(--white);
  box-shadow: calc(-0.3 * var(--size)) 0 calc(1.2 * var(--size)) 0
    rgba(var(--black-rgb), 0.15);
  width: calc(var(--size) * 26);
  top: calc(-1.25 * var(--size));
  right: calc(-1 * var(--size));
  z-index: 250;
  padding-bottom: var(--size);
}

/* Hamburger menu item */
.hamburger__item {
  padding: 0 calc(var(--padding) * 1.5);
  line-height: calc(var(--size) * 5);
  height: calc(var(--size) * 5);
  opacity: 1;
  position: relative;
  cursor: pointer;
  display: flex;
}
.hamburger__item:hover {
  background: var(--grey-ee);
}
.hamburger__item--danger * {
  color: var(--red);
}
.hamburger__item > i.spinner.loading {
  width: calc(1.9 * var(--size));
}

/* Icons */
.hamburger--closed .icon:not(.loading) {
  width: var(--hamburger-icon-size);
  height: var(--hamburger-icon-size);
  border-radius: 50%;
  line-height: var(--hamburger-icon-size);
  color: var(--grey-f7);
  background-color: var(--orange);
  box-shadow: 0 calc(var(--size) * 0.3) calc(var(--size) * 0.6) 0
    rgba(var(--black-rgb), 0.15);
}
.hamburger__icon.icon {
  font-size: var(--font-M);
  margin-right: calc(var(--margin) * 0.5);
}

/* Resource that is in the creating state */
.hamburger__icon.loading {
  width: var(--hamburger-icon-size);
  height: var(--hamburger-icon-size);
  margin-top: calc(var(--margin) * 1.7);
  color: var(--orange);
}

/* The closed dots on open menu*/
.hamburger__dots {
  position: absolute;
  right: var(--size);
  top: calc(var(--size) * 1.25);
  width: var(--hamburger-icon-size);
  height: var(--hamburger-icon-size);
  border-radius: 50%;
  text-align: center;
  line-height: calc(var(--size) * 2.3);
  color: var(--orange);
  z-index: 4;
  cursor: pointer;
}
.hamburger__dots .icon {
  padding-top: 1px;
}
.hamburger__dots:hover {
  color: var(--white) !important;
  background-color: var(--orange);
}
