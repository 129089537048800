/* This checkbox shall be used only in lists and tds where the checkbox has no text */
/* Example : Sortable lists */

.checkbox.list-checkbox {
  position: relative;
}

.checkbox.list-checkbox label {
  background-color: var(--grey-ee);
  border: var(--border) solid var(--grey-ee);
  border-radius: 50%;
  cursor: pointer;
  height: calc(var(--size) * 2.2);
  position: absolute;
  top: calc(-0.1 * var(--size));
  width: calc(var(--size) * 2.2);
  left: 5px;
}
.checkbox.list-checkbox label:before {
  display: none;
}
.checkbox.list-checkbox label:after {
  border: calc(3 * var(--border)) solid var(--white);
  border-top: none;
  border-right: none;
  content: "";
  height: calc(var(--size) * 0.7);
  left: calc(var(--size) * 0.4);
  opacity: 0;
  position: absolute;
  top: calc(var(--size) * 0.5);
  transform: rotate(-45deg);
  width: calc(var(--size) * 1.1);
}

.checkbox.list-checkbox input[type="checkbox"] {
  visibility: hidden;
}
.checkbox.list-checkbox input[type="checkbox"]:checked + label {
  background-color: var(--orange);
  border-color: var(--orange);
}
.checkbox.list-checkbox input[type="checkbox"]:checked + label:after {
  opacity: 1;
  color: transparent;
}
