@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes slide-from-right {
  0% {
    left: 100%;
  }
  100% {
    left: 0;
  }
}

@keyframes shaking {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }
  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }
  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }
  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

@keyframes animate-height {
  0% {
    max-height: 0;
    opacity: 0;
  }
  99% {
    max-height: calc(var(--size) * 30);
    opacity: 1;
  }
  100% {
    max-height: auto;
    opacity: 1;
  }
}

@keyframes rotate-clockwise {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes progress-opacity-halfway {
  0% {
    opacity: 1;
  }
  50%,
  100% {
    opacity: 0;
  }
}

@keyframes progress-text {
  0% {
    transform: translateX(170%);
  }
  90%,
  100% {
    transform: translateX(-200%);
  }
}

@keyframes animate-gardener-info {
  0% {
    width: 22px;
    height: 22px;
  }
  25%,
  90% {
    width: 350px;
    height: 22px;
  }
  100% {
    width: 22px;
    height: 22px;
  }
}

@keyframes user-attention--input {
  0%,
  80% {
    box-shadow: 0 0 1px 2px var(--orange);
  }
  100% {
    box-shadow: 0 0 0 0 transparent;
  }
}

@keyframes user-attention {
  0%,
  80% {
    color: var(--orange);
  }
  100% {
    color: var(--grey-66);
  }
}
