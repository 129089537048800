/* Expanded tr in a react table */
.react-table--expanded-tr .react-table {
  border-spacing: 0 calc(var(--size) * 0.5) !important;
}
.react-table--expanded-tr .react-table thead th {
  padding-top: 0;
  padding-bottom: 0;
}
.react-table--expanded-tr__content {
  background: var(--white);
  position: absolute;
  cursor: default;
  left: calc(var(--size) * 2);
  right: calc(var(--size) * 2);
  padding-left: var(--padding);
  padding-right: var(--padding);
}

.more-data .react-table {
  border-collapse: separate;
  border-spacing: 0 var(--size);
  width: 100%;
}

/* THEAD */
.more-data .react-table thead tr:nth-child(even) {
  background-color: transparent;
}

/* Styles for both THs and TDs */
.more-data .react-table :is(th, td) {
  text-align: center;
}
.more-data .react-table :is(th, td):first-child {
  text-align: left;
}
.more-data .react-table :is(th, td).padding-left {
  padding-left: var(--padding) !important;
}
.more-data .react-table :is(th, td) > i {
  font-size: var(--font-2XL);
}

/* TH sorting icon */
.react-table .sort--disabled {
  background-size: calc(var(--size) * 0.8) calc(var(--size) * 0.8);
  height: var(--size);
  width: var(--size);
  background-repeat: no-repeat;
  margin-left: var(--margin);
  display: none;
}
.react-table .sort--desc {
  background-size: calc(var(--size) * 0.8) calc(var(--size) * 0.8);
  height: var(--size);
  width: var(--size);
  background-repeat: no-repeat;
  margin-left: var(--margin);
  display: inline-block;
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/PjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+PHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJDYXBhXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IiB3aWR0aD0iMjkyLjM2MnB4IiBoZWlnaHQ9IjI5Mi4zNjJweCIgdmlld0JveD0iMCAwIDI5Mi4zNjIgMjkyLjM2MiIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMjkyLjM2MiAyOTIuMzYyOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PGc+PHBhdGggZD0iTTI4Ni45MzUsNjkuMzc3Yy0zLjYxNC0zLjYxNy03Ljg5OC01LjQyNC0xMi44NDgtNS40MjRIMTguMjc0Yy00Ljk1MiwwLTkuMjMzLDEuODA3LTEyLjg1LDUuNDI0QzEuODA3LDcyLjk5OCwwLDc3LjI3OSwwLDgyLjIyOGMwLDQuOTQ4LDEuODA3LDkuMjI5LDUuNDI0LDEyLjg0N2wxMjcuOTA3LDEyNy45MDdjMy42MjEsMy42MTcsNy45MDIsNS40MjgsMTIuODUsNS40MjhzOS4yMzMtMS44MTEsMTIuODQ3LTUuNDI4TDI4Ni45MzUsOTUuMDc0YzMuNjEzLTMuNjE3LDUuNDI3LTcuODk4LDUuNDI3LTEyLjg0N0MyOTIuMzYyLDc3LjI3OSwyOTAuNTQ4LDcyLjk5OCwyODYuOTM1LDY5LjM3N3oiLz48L2c+PGc+PC9nPjxnPjwvZz48Zz48L2c+PGc+PC9nPjxnPjwvZz48Zz48L2c+PGc+PC9nPjxnPjwvZz48Zz48L2c+PGc+PC9nPjxnPjwvZz48Zz48L2c+PGc+PC9nPjxnPjwvZz48Zz48L2c+PC9zdmc+);
}
.react-table .sort--asc {
  background-size: calc(var(--size) * 0.8) calc(var(--size) * 0.8);
  height: var(--size);
  width: var(--size);
  background-repeat: no-repeat;
  margin-left: var(--margin);
  display: inline-block;
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/PjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+PHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJDYXBhXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IiB3aWR0aD0iMjkyLjM2MnB4IiBoZWlnaHQ9IjI5Mi4zNjJweCIgdmlld0JveD0iMCAwIDI5Mi4zNjIgMjkyLjM2MiIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMjkyLjM2MiAyOTIuMzYyOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PGc+PHBhdGggZD0iTTI4Ni45MzUsMTk3LjI4NkwxNTkuMDI4LDY5LjM3OWMtMy42MTMtMy42MTctNy44OTUtNS40MjQtMTIuODQ3LTUuNDI0cy05LjIzMywxLjgwNy0xMi44NSw1LjQyNEw1LjQyNCwxOTcuMjg2QzEuODA3LDIwMC45LDAsMjA1LjE4NCwwLDIxMC4xMzJzMS44MDcsOS4yMzMsNS40MjQsMTIuODQ3YzMuNjIxLDMuNjE3LDcuOTAyLDUuNDI4LDEyLjg1LDUuNDI4aDI1NS44MTNjNC45NDksMCw5LjIzMy0xLjgxMSwxMi44NDgtNS40MjhjMy42MTMtMy42MTMsNS40MjctNy44OTgsNS40MjctMTIuODQ3UzI5MC41NDgsMjAwLjksMjg2LjkzNSwxOTcuMjg2eiIvPjwvZz48Zz48L2c+PGc+PC9nPjxnPjwvZz48Zz48L2c+PGc+PC9nPjxnPjwvZz48Zz48L2c+PGc+PC9nPjxnPjwvZz48Zz48L2c+PGc+PC9nPjxnPjwvZz48Zz48L2c+PGc+PC9nPjxnPjwvZz48L3N2Zz4=);
}

/* TH disable the top row created by React-Table */
.react-table thead tr:first-child th[role="columnheader"] span,
.react-table thead th:first-child span {
  display: none;
}

/* TD */
.more-data .react-table td:first-child {
  padding-left: calc(var(--padding) * 2) !important;
}
.more-data .react-table td:first-child > i {
  margin-left: calc(-0.5 * var(--margin));
}
.more-data .react-table td {
  background-color: var(--grey-f7);
  padding: calc(var(--padding) * 0.2) 0 0 0 !important;
}
.more-data .react-table td:first-child {
  border-radius: calc(var(--size) * 2) 0 0 calc(var(--size) * 2);
}
.more-data .react-table td:last-child {
  border-radius: 0 calc(var(--size) * 2) calc(var(--size) * 2) 0;
}
.more-data .react-table td > i {
  margin: 0;
  padding-left: 10px;
}
.more-data .react-table td > i.spinner.loading {
  padding-left: 0;
}
.more-data .react-table td > div:not(.quick-view__wrapper) {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
