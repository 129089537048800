:root {
  --orange: #ff6600;
  --orange-dark: #f54613;

  --blue: #2643ff;
  --blue-dark: #001dd9;
  --blue-light: #00ddff;

  --green: #2ecc71;
  --green-dark: #06bc0b;
  --green-light: #caf2db;

  --red: #ff2222;
  --red-dark: #ce1b1b;

  --black: #000000;
  --black-rgb: 0, 0, 0;
  --grey-33: #333333;
  --grey-45: #454545;
  --grey-66: #666666;
  --grey-80: #808080;
  --grey-99: #999999;
  --grey-cc: #cccccc;
  --grey-dd: #dddddd;
  --grey-ee: #eeeeee;
  --grey-f7: #f7f7f7;
  --grey-fc: #fcfcfc;
  --white: #ffffff;
  --white-rgb: 255, 255, 255;

  --border-color: rgba(45, 45, 45, 0.1) /* grey-light; */;
}
