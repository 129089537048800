.with-scrolling-text,
.item.with-scrolling-text {
  overflow: hidden;
  flex: 1;
  width: 100%;
  position: relative;
}

.with-scrolling-text > :is(div, span),
.item.with-scrolling-text > :is(div, span) {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.with-scrolling-text:hover,
.item.with-scrolling-text:hover {
  box-shadow: var(--size) 0 var(--grey-f7) !important;
}

.with-scrolling-text:hover :is(div, span),
.item.with-scrolling-text:hover :is(div, span) {
  width: auto;
  transform: translateX(-100%);
  transition:
    transform 2s,
    margin-left 2s,
    width 2s ease-in;
}

.select-option-scrolling:hover :is(div, span) {
  margin-left: calc(21 * var(--size)) !important;
}

.quick-view-option-scrolling:hover :is(div, span) {
  margin-left: calc(26 * var(--size)) !important;
}
