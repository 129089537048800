html,
body {
  font-size: var(--font-S);
  color: var(--grey-33);
  font-family: var(--font-family);
}
/* Reset font-smoothing */
body {
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
}

tbody td {
  font-weight: normal;
  padding-right: calc(var(--size) * 0.5);
}

* {
  font-family: var(--font-family);
}

p {
  color: var(--grey-66);
  font-family: inherit;
  outline: 0;
}

/* Headings */
:is(h1, h2, h3, h4, h5, h6) {
  color: var(--grey-66);
  font-family: inherit;
  outline: 0;
}

:is(h1, h2, h3) {
  font-size: var(--font-M);
}

:is(h4, h5) {
  font-size: var(--font-S);
}

h6 {
  font-size: var(--font-XS);
}

/* Lists */
ul {
  padding-left: 20px;
  margin-top: 0;
  margin-bottom: 0;
}

li {
  color: var(--grey-66);
}

/* Anchor tags */
a {
  line-height: inherit;
  color: var(--orange);
  text-decoration: none;
  cursor: pointer;
}

a:hover,
a:focus {
  color: var(--blue);
}

/* Form elements */
input {
  display: block;
  width: 100%;
}

label {
  font-size: var(--font-S);
}

:is(button, input, textarea, select) {
  font-family: inherit;
  outline: 0;
}

textarea,
textarea:focus {
  display: block;
  width: 100%;
  background-color: var(--grey-f7);
  resize: none;
  border-radius: calc(var(--size) * 2);
  border: 0 none;
  padding: var(--padding) calc(var(--padding) * 2) calc(var(--padding) * 2);
}

button {
  cursor: pointer;
  background-color: transparent;
  border: 0 none;
}
