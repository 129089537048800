.horizontal-tabs {
  background: var(--grey-f7) !important;
}

.horizontal-tabs .ui.tabular.menu {
  flex-wrap: nowrap;
  overflow-x: auto;
  border-bottom: 0;
  padding-left: var(--size);
}

/* headers */
.horizontal-tabs .ui.tabular.menu .item {
  margin-left: var(--size);
  background: var(--grey-ee);
  padding-left: calc(var(--size) * 4);
  padding-right: calc(var(--size) * 4);
  border: 0 solid transparent;
  height: var(--row-height);
  border-radius: var(--size) var(--size) 0 0;
  text-transform: capitalize;
}
.horizontal-tabs .ui.tabular.menu .item span {
  font-weight: 700;
}
.horizontal-tabs .ui.tabular.menu .item:hover {
  background-color: var(--blue);
}
.horizontal-tabs .ui.tabular.menu .item:hover span {
  color: var(--white) !important;
}
.horizontal-tabs .ui.tabular.menu .item.active {
  background-color: var(--white);
  border-radius: var(--size) var(--size) 0 0 !important;
}
.horizontal-tabs .ui.tabular.menu .item.active span {
  color: var(--grey-33) !important;
}

/* body */
.horizontal-tabs .tab.active {
  border: 0 transparent;
  padding-left: calc(var(--size) * 2);
  padding-right: calc(var(--size) * 2);
}

@media screen and (max-width: 700px) {
  .horizontal-tabs .ui.tabular.menu .item {
    padding-left: 0;
    padding-right: 0;
  }
}

@media screen and (min-width: 701px) and (max-width: 1100px) {
  .horizontal-tabs .ui.tabular.menu .item {
    padding-left: calc(var(--size) * 2);
    padding-right: calc(var(--size) * 2);
  }
}

/* Has padding on the left and right */
.horizontal-tabs.with-padding .ui.tabular.menu {
  padding-left: calc(var(--size) * 3);
  padding-right: calc(var(--size) * 3);
}

/* Tabs with White background */
.horizontal-tabs--white {
  background: transparent !important;
}
.horizontal-tabs--white .ui.tabular.menu {
  border-bottom: 1px solid var(--grey-ee);
  overflow: visible;
}
.horizontal-tabs--white .ui.tabular.menu .item.active {
  border: 1px solid var(--grey-dd);
  border-bottom: 0;
  border-radius: var(--size) var(--size) 0 0 !important;
  position: relative;
}
.horizontal-tabs--white .ui.tabular.menu .item.active::after {
  left: 0;
  right: 0;
  bottom: -1px;
  border-bottom: 1px solid white;
  content: "";
  z-index: 1;
  position: absolute;
}

/* Tabs with Red background */
/* Used on Delete Account */
.horizontal-tabs .ui.tabular.menu .item.menu-item--danger {
  border: var(--grey-dd) 1px solid;
  border-bottom: 0;
  color: var(--red-dark);
}
.horizontal-tabs .ui.tabular.menu .item.menu-item--danger:hover {
  background: var(--red);
  color: white;
}
.horizontal-tabs .ui.tabular.menu .item.menu-item--danger.active {
  background-color: var(--red);
}
.horizontal-tabs .ui.tabular.menu .item.menu-item--danger.active span {
  color: var(--white) !important;
}
