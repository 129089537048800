.error-notification {
  transition: bottom 200ms;
  position: fixed;
  right: var(--row-height);
  width: calc(var(--size) * 60);
  max-height: calc(var(--size) * 50);
  min-height: calc(var(--size) * 20);
  background: var(--white);
  overflow-y: auto;
  border-radius: calc(var(--size) * 1.5);
  z-index: 9000;
  border: var(--border) solid rgba(var(--black-rgb), 0.05);
  box-shadow: 0 0 var(--size) 0 rgba(var(--black-rgb), 0.2);
}
.error-notification.close {
  bottom: calc(-50 * var(--size));
  pointer-events: none;
}
.error-notification.open {
  bottom: var(--row-height);
  pointer-events: all;
}

.error-notification__actions {
  background-color: var(--white);
  padding: calc(var(--padding) * 1.5);
  box-shadow: 0 calc(0.8 * var(--size)) calc(1.3 * var(--size))
    calc(-0.8 * var(--size)) rgba(var(--black-rgb), 0.1) inset;
}
