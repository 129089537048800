.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: inherit;
  outline: 0;
}
.ui.popup {
  max-width: calc(var(--size) * 40);
}
