.viz-sensor {
  margin-top: -100px;
  height: 400px;
  position: relative;
  pointer-events: none;
  z-index: 0;
  border: 0 none;
}
.viz-sensor .loader {
  top: -100px !important;
}
