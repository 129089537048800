.logo-wrapper {
  margin: 0 0 0 calc(var(--margin) * 1.5) !important;
  max-height: var(--row-height);
  display: flex;
  align-items: center;
  flex: 1;
}

.project-selector {
  display: flex;
  margin: auto;
  align-items: center;
}

.app-header {
  z-index: 210;
  display: flex;
  height: var(--row-height);
  min-height: var(--row-height);
  margin: 0;
  padding: 0;
  transition: all 200ms ease-in-out;
  position: fixed;
  box-shadow: 0 calc(0.3 * var(--size)) calc(0.4 * var(--size)) 0
    rgba(var(--black-rgb), 0.1);
  top: 0;
  left: 0;
  background: var(--orange);
  align-items: center;
  width: 100%;
}

.app-header.inactive:after {
  display: block;
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(var(--black-rgb), 0.15);
}

.app-header .action-buttons {
  height: var(--row-height);
  float: none;
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.app-header .action-buttons button.knowledgebase-toggle {
  height: calc(var(--size) * 3);
  line-height: calc(var(--size) * 3);
  width: calc(var(--size) * 3);
  box-shadow: 0 0 0 0 transparent;
  background-color: transparent;
  padding: 0;
  border-radius: 0;
  position: relative;
  z-index: 1000;
}
.app-header .action-buttons button.knowledgebase-toggle i {
  border-radius: 50%;
  height: calc(var(--size) * 3) !important;
  width: calc(var(--size) * 3);
  line-height: calc(var(--size) * 3);
}
.app-header .action-buttons button.knowledgebase-toggle.active {
  background-color: transparent;
}

.app-header .action-buttons .icon {
  color: var(--white);
  font-size: var(--font-XL);
  text-align: center;
  opacity: 1 !important;
  margin: 0 !important;
}
.app-header .action-buttons .icon i {
  opacity: 1 !important;
  margin: 0;
}
