/* OS Icon used for servers and images */
/* These are for tables and shall be removed after Grid is fully in place*/
.os-icon-holder {
  border-radius: 50%;
  margin-left: 0;
  box-shadow: 0 calc(0.1 * var(--size)) calc(0.5 * var(--size)) 0
    rgba(var(--black-rgb), 0.2);
  width: calc(var(--size) * 3);
  height: calc(var(--size) * 3);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 3;
}
.os-icon-holder svg {
  width: calc(var(--size) * 1.8);
  height: calc(var(--size) * 1.8);
  z-index: 10;
  text-overflow: ellipsis;
  height: 50px;
  line-height: 50px;
  white-space: nowrap;
  overflow: hidden;
}

/* Icon for resources that have images  */
.image-related-resources-icon-wrapper {
  position: relative;
  margin-left: 10px;
}
.image-related-resources-icon-wrapper__icon {
  border-radius: 50%;
  box-shadow: 0 calc(0.1 * var(--size)) calc(0.5 * var(--size)) 0
    rgba(var(--black-rgb), 0.2);
  width: calc(var(--size) * 3);
  height: calc(var(--size) * 3);
  display: grid;
  place-content: center;
  justify-content: center;
  position: relative;
  z-index: 3;
}
.image-related-resources-icon-wrapper__icon svg {
  max-width: 20px;
  aspect-ratio: 1;
}
.image-related-resources-icon-wrapper .status-icon {
  margin-top: 0;
  top: calc(var(--size) * 1.5);
  left: calc(var(--size) * 2.7);
}
.image-related-resources-icon-wrapper .circular-progress {
  position: absolute;
  z-index: -1;
  background: var(--white);
  width: calc(var(--size) * 3);
  height: calc(var(--size) * 3);
  overflow: hidden;
  top: 0;
  margin-left: 0;
}
.image-related-resources-icon-wrapper .circular-progress__text {
  max-width: calc(4 * var(--size));
  height: calc(var(--size) * 3);
  line-height: calc(var(--size) * 3);
  top: 0;
}

/* Status Icon like for networks, invoices, volumes and so on */
.row_Icon {
  width: calc(var(--size) * 8);
  display: flex;
  align-items: center;
  padding-left: 2px;
}
.row_Icon .icon-only {
  padding-top: 5px;
  padding-left: 2px;
}
.row_Icon i {
  font-size: var(--font-2XL);
  position: relative;
  z-index: 3;
}
.row_Icon i.cog {
  top: calc(-0.1 * var(--size));
  left: 1px;
}
