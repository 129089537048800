.ui.styled.accordion,
.ui.styled.accordion .accordion {
  background-color: transparent;

  box-shadow: 0 0 0 0 transparent;
}
.ui.styled.accordion {
  width: 100%;
}

/* Title */
.ui.styled.accordion .title {
  border-top: var(--border) solid transparent;
  position: relative;
  border-radius: 0;
  color: var(--grey-33);
  margin: var(--margin) 0 0 0;
}
.ui.styled.accordion .title:hover {
  border-radius: calc(var(--size) * 2);
  background-color: var(--grey-f7);
}

.ui.styled.accordion .title:after {
  display: block;
  border-bottom: var(--border) solid var(--grey-ee);
  content: "";
  position: absolute;
  bottom: 0;
  left: calc(var(--size) * 1.5);
  right: calc(var(--size) * 1.5);
}
.ui.styled.accordion .title:hover:after {
  display: none;
}

/* Subtitle */
.accordion .title .subtitle * {
  color: var(--grey-33);
}
.accordion .title .subtitle:hover * {
  color: var(--orange);
}
.accordion .title .subtitle {
  font-size: 0.95em;
  display: inline-flex;
}

/* Title Active State*/
.ui.styled.accordion .title.active {
  border-bottom: var(--border) solid transparent;

  font-style: normal;
  background-color: var(--grey-fc);
  border-radius: calc(var(--size) * 2) calc(var(--size) * 2) 0 0;
}
.ui.styled.accordion .title.active:after {
  display: none;
}

/* Content */
.ui.styled.accordion .content.active {
  background-color: var(--grey-fc);
  padding-top: var(--padding);
  border-radius: 0 0 calc(var(--size) * 2) calc(var(--size) * 2);
}

/* Separated Accordion Items */
.accordion.ui.accordion--separated > .title {
  border: 1px solid #eee;
  background-color: transparent;
  border-radius: 20px !important;
  padding-left: 20px;
}
.accordion.ui.accordion--separated > .title:first-child {
  border-top: 1px solid #eee;
}
.accordion.ui.accordion--separated > .title.active {
  border-bottom: 1px solid transparent;
  border-radius: 20px 20px 0 0 !important;
  background-color: transparent;
}
.accordion.ui.accordion--separated > .content.active {
  border: 1px solid #eee;
  background-color: transparent;

  border-top: 1px solid transparent;
  border-radius: 0 0 20px 20px !important;
  padding: 0;
}

/* Accordion Quota used on Projects Detailed View */
.accordion-quota-wrapper--text {
  writing-mode: vertical-lr;
  text-align: center;
  position: relative;
}
.accordion-quota-wrapper--text h3 {
  display: inline-block;
  z-index: 5;
  padding: var(--padding) 0;
  font-style: italic;
  background-color: var(--grey-fc);
  position: relative;
}
.accordion-quota-wrapper--text:after {
  display: block;
  border-left: var(--border) solid var(--grey-ee);
  content: "";
  position: absolute;
  left: 45%;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
}
.accordion-quota-wrapper--quotas {
  flex: 1;
}
