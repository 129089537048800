.autocomplete {
  flex: 1;
  position: relative;
}

.autocomplete input,
.autocomplete input:focus {
  margin-bottom: 0;
  border: 0 none;
  padding: calc(var(--padding) * 0.5) calc(var(--padding) * 2);
  border-radius: calc(2 * var(--size));
  background-color: var(--grey-ee);
  height: calc(var(--size) * 4);
  line-height: calc(var(--size) * 4);
}

.autocomplete .no-suggestions {
  color: var(--red-dark);
  margin: calc(var(--margin) * 0.5) 0 calc(var(--margin) * 2)
    calc(var(--margin) * 2);
  position: absolute;
}

.autocomplete .suggestions {
  list-style: none;
  overflow-y: auto;
  width: calc(100% - (calc(var(--size) * 5)));
  position: absolute;
  margin: 0;
  left: calc(var(--size) * 2.5);
  top: calc(var(--size) * 3);
  border-radius: calc(var(--size) * 2);
  background: var(--white);
  padding: calc(var(--padding) * 2) 0;
  box-shadow: 0 0 calc(2 * var(--size)) 0 var(--grey-cc);
  z-index: 50;
}

.autocomplete .suggestion {
  border: 0 solid transparent !important;
  padding: var(--padding) calc(var(--padding) * 2);
}
.autocomplete .suggestion:hover {
  background-color: var(--grey-ee) !important;
  cursor: pointer;
}

.autocomplete .suggestions .suggestion--active {
  background-color: var(--grey-ee) !important;
  cursor: pointer;
  color: var(--black);
  font-weight: bold;
}
