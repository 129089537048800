.margin-auto {
  margin: auto;
}
.margin-top--auto {
  margin-top: auto;
}
.margin-right--auto {
  margin-right: auto;
}
.margin-bottom--auto {
  margin-bottom: auto;
}
.margin-left--auto {
  margin-left: auto;
}

.margin-top-minus {
  margin-top: calc(-1 * var(--margin)) !important;
}
.margin-top-00 {
  margin-top: 0 !important;
}
.margin-top {
  margin-top: var(--margin) !important;
}
.margin-top-quarter {
  margin-top: calc(var(--margin) * 0.25) !important;
}
.margin-top-half {
  margin-top: calc(var(--margin) * 0.5) !important;
}
.margin-top-3quarter {
  margin-top: calc(var(--margin) * 0.75) !important;
}
.margin-top-10 {
  margin-top: calc(var(--margin) * 1) !important;
}
.margin-top-15 {
  margin-top: calc(var(--margin) * 1.5) !important;
}
.margin-top-20 {
  margin-top: calc(var(--margin) * 2) !important;
}
.margin-top-30 {
  margin-top: calc(var(--margin) * 3) !important;
}
.margin-top-40 {
  margin-top: calc(var(--margin) * 4) !important;
}
.margin-top-50 {
  margin-top: calc(var(--margin) * 5) !important;
}

.margin-bottom-minus {
  margin-bottom: calc(-1 * var(--margin)) !important;
}
.margin-bottom-00 {
  margin-bottom: 0 !important;
}
.margin-bottom {
  margin-bottom: var(--margin) !important;
}
.margin-bottom-quarter {
  margin-bottom: calc(var(--margin) * 0.25) !important;
}
.margin-bottom-half {
  margin-bottom: calc(var(--margin) * 0.5) !important;
}
.margin-bottom-10 {
  margin-bottom: calc(var(--margin) * 1) !important;
}
.margin-bottom-15 {
  margin-bottom: calc(var(--margin) * 1.5) !important;
}
.margin-bottom-20 {
  margin-bottom: calc(var(--margin) * 2) !important;
}
.margin-bottom-30 {
  margin-bottom: calc(var(--margin) * 3) !important;
}
.margin-bottom-40 {
  margin-bottom: calc(var(--margin) * 4) !important;
}
.margin-bottom-50 {
  margin-bottom: calc(var(--margin) * 5) !important;
}

.margin-right-minus {
  margin-right: calc(-1 * var(--margin)) !important;
}
.margin-right-00 {
  margin-right: 0 !important;
}
.margin-right {
  margin-right: var(--margin) !important;
}
.margin-right-quarter {
  margin-right: calc(var(--margin) * 0.25) !important;
}
.margin-right-half {
  margin-right: calc(var(--margin) * 0.5) !important;
}
.margin-right-10 {
  margin-right: calc(var(--margin) * 1) !important;
}
.margin-right-15 {
  margin-right: calc(var(--margin) * 1.5) !important;
}
.margin-right-20 {
  margin-right: calc(var(--margin) * 2) !important;
}
.margin-right-30 {
  margin-right: calc(var(--margin) * 3) !important;
}
.margin-right-40 {
  margin-right: calc(var(--margin) * 4) !important;
}
.margin-right-50 {
  margin-right: calc(var(--margin) * 5) !important;
}

.margin-left-minus {
  margin-left: calc(-1 * var(--margin)) !important;
}
.margin-left-00 {
  margin-left: 0 !important;
}
.margin-left {
  margin-left: var(--margin) !important;
}
.margin-left-quarter {
  margin-left: calc(var(--margin) * 0.25) !important;
}
.margin-left-half {
  margin-left: calc(var(--margin) * 0.5) !important;
}
.margin-left-10 {
  margin-left: calc(var(--margin) * 1) !important;
}
.margin-left-15 {
  margin-left: calc(var(--margin) * 1.5) !important;
}
.margin-left-20 {
  margin-left: calc(var(--margin) * 2) !important;
}
.margin-left-30 {
  margin-left: calc(var(--margin) * 3) !important;
}
.margin-left-40 {
  margin-left: calc(var(--margin) * 4) !important;
}
.margin-left-50 {
  margin-left: calc(var(--margin) * 5) !important;
}
.margin-left-auto {
  margin-left: auto !important;
}
