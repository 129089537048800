.DatePicker.customized {
  width: 100%;
  height: calc(var(--size) * 4);
}
.DatePicker .DatePicker__input {
  border-radius: calc(var(--size) * 2);
  width: 100%;
  height: 100%;
  background-color: var(--grey-ee);
  border: 0 transparent solid;
  color: rgba(0, 0, 0, 0.87);
  cursor: pointer;
  padding: calc(var(--size) * 1.5);
  text-align: left;
  font-size: var(--font-S);
}

.DatePicker.select-box .DatePicker__input {
  background-color: var(--white);
  box-shadow: 0 calc(var(--size) * 0.5) calc(var(--size) * 1.5) 0
    rgba(0, 0, 0, 0.1);
}

.DatePicker .Calendar__yearSelectorItem.-active .Calendar__yearSelectorText,
.DatePicker
  .Calendar__monthSelectorItem.-active
  .Calendar__monthSelectorItemText,
.DatePicker .Calendar__day.-selected,
.Calendar__day.-selectedEnd,
.Calendar__day.-selectedStart {
  background: var(--blue);
}

.DatePicker .Calendar__day.-selectedBetween {
  background: var(--grey-dd);
  color: var(--blue);
}

.DatePicker .Calendar__day.-ltr.-selectedStart {
  border-top-left-radius: calc(var(--size) * 1.5);
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: calc(var(--size) * 1.5);
  color: var(--white);
}

.DatePicker .Calendar__day.-ltr.-selectedEnd {
  border-top-left-radius: 0;
  border-top-right-radius: calc(var(--size) * 1.5);
  border-bottom-right-radius: calc(var(--size) * 1.5);
  border-bottom-left-radius: 0;
  color: var(--white);
}

.DatePicker .Calendar__yearText,
.Calendar__yearText:hover,
.DatePicker .Calendar__monthText,
.Calendar__monthText:hover {
  font-weight: normal;
  transform: none;
}

.DatePicker .Calendar__weekDay {
  cursor: default;
  border: 0 none;
}
