.error-form-item {
  position: relative;
}
.error-form-item:after {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  content: "";
  border-radius: calc(var(--size) * 2);
  box-shadow: 0 0 calc(0.1 * var(--size)) calc(0.1 * var(--size)) var(--orange);
  z-index: 9;
  pointer-events: none;
  background-color: rgba(var(--orange), 0.03);
}
.error-form-item.active:after {
  border-radius: calc(var(--size) * 2) calc(var(--size) * 2) 0 0;
}

.error-form-group {
  position: relative;
}
.error-form-group:after {
  position: absolute;
  left: calc(-1 * var(--size));
  right: calc(-1 * var(--size));
  top: 0;
  bottom: calc(-1 * var(--size));
  content: "";
  border-radius: calc(var(--size) * 2);
  border: calc(2 * var(--border)) solid var(--orange);
  pointer-events: none;
}

.icon.advanced_icon {
  margin-right: calc(var(--margin) * 1.5);
  box-shadow: 0 calc(0.3 * var(--size)) calc(0.6 * var(--size)) 0
    rgba(var(--black-rgb), 0.15);
  border-radius: 50%;
  font-size: var(--font-2XL);
  width: calc(var(--size) * 2);
  line-height: calc(var(--size) * 2.2);
}
.icon.advanced_icon + span {
  line-height: calc(var(--size) * 2.2);
  height: calc(var(--size) * 2.2);
  align-self: center;
}

.bold-section {
  box-shadow: 0 calc(0.4 * var(--size)) calc(1.2 * var(--size)) 0
    rgba(var(--black-rgb), 0.15);
  padding: calc(var(--padding) * 2);
  position: relative;
  width: 100%;
  border: var(--orange) var(--border) solid;
  border-radius: calc(var(--size) * 2);
}
.bold-section:after {
  clear: both;
  display: block;
  content: "";
  height: 0;
}

.warning-section {
  border-color: var(--orange);
  box-shadow: 0 calc(0.4 * var(--size)) calc(1.2 * var(--size)) 0
    rgba(var(--orange), 0.15);
}

.error-section {
  border-color: var(--red);
  box-shadow: 0 calc(0.4 * var(--size)) calc(1.2 * var(--size)) 0
    rgba(var(--red), 0.15);
}

.idea-section {
  border-color: var(--blue);
  box-shadow: 0 calc(0.4 * var(--size)) calc(1.2 * var(--size)) 0
    rgba(var(--blue), 0.15);
}

.shake {
  animation: shaking 0.8s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
  z-index: 8;
  backface-visibility: hidden;
  perspective: 1000px;
}
