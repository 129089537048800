.border-right {
  border-right: var(--border) solid var(--grey-ee);
}
.border-color-red {
  border-color: var(--red);
}
.border-color-orange {
  border-color: var(--orange);
}
.border-color-green {
  border-color: var(--green);
}
