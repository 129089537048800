.grid-list {
  display: grid;
  text-align: left;
  position: relative;
  /* The checkbox on the left shall be 50px */
  /* The status icon on the left shall be 80px */
  /* The hamburger menu on the right shall be 50px */
  --checkbox: calc(5 * var(--size));
  --status: calc(8 * var(--size));
  --hamburger-menu: calc(4 * var(--size));
}

/* Defining Columns for individual resource list */
.grid-columns--server_snapshots,
.grid-columns--keypairs {
  grid-template-columns:
    var(--checkbox)
    var(--status)
    repeat(4, minmax(50px, auto))
    var(--hamburger-menu);
}

.grid-columns--ikes,
.grid-columns--endpointgroups,
.grid-columns--volume_snapshots,
.grid-columns--floatingips,
.grid-columns--securitygroups,
.grid-columns--vpns,
.grid-columns--openstack_users,
.grid-columns--orchestration_stacks {
  grid-template-columns:
    var(--checkbox)
    var(--status)
    repeat(5, minmax(50px, auto))
    var(--hamburger-menu);
}

.grid-columns--ipsecs,
.grid-columns--loadbalancers,
.grid-columns--cleura_users,
.grid-columns--ports {
  grid-template-columns:
    var(--checkbox)
    var(--status)
    repeat(7, minmax(50px, auto))
    var(--hamburger-menu);
}

.grid-columns--gardener_shoots {
  grid-template-columns:
    var(--checkbox)
    var(--status)
    repeat(4, minmax(50px, auto))
    calc(1.5 * var(--status))
    repeat(2, minmax(50px, auto))
    var(--hamburger-menu);
}

.grid-columns--volumes {
  grid-template-columns:
    var(--checkbox)
    var(--status)
    repeat(8, minmax(50px, auto))
    var(--hamburger-menu);
}

.grid-columns--servers {
  grid-template-columns:
    var(--checkbox)
    var(--status)
    repeat(9, minmax(50px, auto))
    var(--hamburger-menu);
}

.grid-columns--subnets,
.grid-columns--privateimages,
.grid-columns--clustertemplates {
  grid-template-columns:
    var(--status)
    repeat(6, minmax(50px, auto))
    var(--hamburger-menu);
}

.grid-columns--publicimages,
.grid-columns--networks,
.grid-columns--routers,
.grid-columns--clusters,
.grid-columns--invoices {
  grid-template-columns:
    var(--status)
    repeat(5, minmax(50px, auto))
    var(--hamburger-menu);
}

.grid-columns--all_users {
  grid-template-columns:
    var(--checkbox)
    var(--status)
    repeat(3, minmax(50px, auto))
    var(--hamburger-menu);
}
.grid-columns--rebatecodes {
  grid-template-columns: repeat(7, minmax(50px, auto));
}
.grid-columns--openstack_logs {
  grid-template-columns: repeat(6, minmax(50px, auto));
}
.grid-columns--domains {
  grid-template-columns: var(--checkbox) repeat(6, minmax(50px, auto));
}
.grid-columns--account_logs {
  grid-template-columns: repeat(5, minmax(50px, auto));
}
.grid-columns--monitoring_logs {
  grid-template-columns: repeat(6, minmax(50px, auto));
}
.grid-columns--monitoring_schedules {
  grid-template-columns:
    var(--status)
    repeat(4, minmax(50px, auto))
    var(--hamburger-menu);
}
.grid-columns--monitoring_contacts {
  grid-template-columns:
    repeat(4, minmax(50px, auto))
    var(--hamburger-menu);
}

/* Header section */
.grid__header-cell {
  height: var(--row-height);
  background: var(--white);
  position: sticky;
  top: calc(14.5 * var(--size));
  padding-top: calc(1.5 * var(--size));
  padding-bottom: calc(1.5 * var(--size));
  z-index: 240;
  border-bottom: calc(2 * var(--border)) solid var(--grey-fc);
  padding-right: var(--size);
}

.grid-list--no-search-box .grid__header-cell,
.grid__header-cell--no-search-box {
  top: calc(9.5 * var(--size));
}
.grid__header-cell:first-child .checkbox {
  z-index: 3;
}
.grid__header-cell:first-child:after {
  background: var(--white);
  content: "";
  position: absolute;
  bottom: 0;
  width: 100vw;
  top: 0;
  left: calc(-3 * var(--size));
}

.grid__header-cell--no-sort {
  cursor: unset;
}

.grid__header-cell--sortable {
  cursor: pointer;
}

.grid__header-cell--sortable:hover * {
  color: black !important;
}

.grid__header-cell--sortable :is(i, span) {
  pointer-events: none;
}

/* Grid individual Cell */
.grid__cell {
  height: var(--row-height);
  display: flex;
  align-items: center;
  margin-top: var(--size);
  padding-right: calc(0.5 * var(--size));
}

/* Hover effect on row when not showing detailed view*/
.grid__cell:hover:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  height: var(--row-height);
  box-shadow: 0 0 calc(2 * var(--size)) 0 rgba(var(--black-rgb), 0.1);
  z-index: -1;
  border-radius: calc(0.5 * var(--row-height));
}

.grid__cell--country {
  grid-column: 1 / -1;
  padding-left: 10px;
}

/* Update grid cell to be on top of the detailed view */
.grid__cell-detailed-active {
  position: relative;
  z-index: 2;
}

.grid__cell-detailed-active:hover:before {
  box-shadow: 0 0 transparent;
}

/* If the resource is disabled */
.grid__cell--disabled {
  color: var(--grey-80);
  font-style: italic;
  position: static;
}
.grid__cell--disabled-popup {
  position: absolute;
  margin-top: calc(-10 * var(--size));
  left: 0px;
  background-color: var(--white);
  border-radius: calc(var(--size) * 0.5);
  box-shadow: 0 2px 10px 0 rgba(var(--black-rgb), 0.2);
  display: none;
  padding: var(--size) calc(var(--size) * 1.5);
  color: var(--black);
  font-style: normal;
  border: 1px solid var(--grey-99);
}
.grid__cell--disabled:hover .grid__cell--disabled-popup {
  display: block;
}

.grid__detailed-view {
  grid-column: 1 / -1;
  border-radius: 0 0 var(--size) var(--size);
  margin-bottom: calc(2 * var(--size));
}

.grid__close-detailed-view {
  position: relative;
  left: 0;
  right: 0;
  grid-column: 1 / -1;
  cursor: crosshair;
  margin-top: calc(-1 * var(--row-height));
  height: var(--row-height);
  z-index: 1;
  box-shadow: 0 0 calc(2 * var(--size)) 0 rgba(var(--black-rgb), 0.1);
  border-radius: calc(0.5 * var(--row-height)) calc(0.5 * var(--row-height)) 0 0;
}

.grid__detailed-view-content {
  box-shadow: 0 0 calc(2 * var(--size)) 0 rgba(var(--black-rgb), 0.1);
  border-top: 1px solid rgba(var(--black-rgb), 0.05);
  border-radius: 0 0 calc(0.5 * var(--row-height)) calc(0.5 * var(--row-height));
  max-height: calc(var(--size) * 200);
  overflow-y: auto;
}
.grid__detailed-view-content .more-data--tabs {
  padding: 0;
}

/* Fixing the placement of 'cog' status icon on grid-cell */
.grid__cell > .icon.cog {
  padding-left: calc(0.4 * var(--size));
}
