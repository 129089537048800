.support-box {
  transition: 200ms all ease-in;
  min-height: calc(var(--size) * 50);
  min-height: calc(var(--size) * 25);
  margin: 0 10px 10px;
  aspect-ratio: auto;
  border-radius: calc(var(--size) * 2.5);
  box-shadow: 0 calc(var(--size) * 0.5) calc(var(--size) * 2) 0
    rgba(var(--black-rgb), 0.1);
}

@media print, screen and (max-width: 800px) {
  .support-box {
    width: 100%;
  }
}
@media print, screen and (min-width: 800px) and (max-width: 1200px) {
  .support-box {
    width: calc(50% - 20px);
  }
}
@media print, screen and (min-width: 1200px) and (max-width: 1600px) {
  .support-box {
    width: calc(33.333% - 20px);
  }
}
@media screen and (min-width: 1600px) {
  .support-box {
    width: calc(25% - 20px);
    max-width: 450px !important;
  }
}

.support-box .segment {
  border-radius: calc(var(--size) * 2.5);
}
.support-box__title {
  font-size: var(--font-2XL);
  padding-top: calc(var(--size) * 3);
  text-transform: capitalize;
  text-align: center;
  margin-bottom: 0;
}
.support-box__description {
  font-size: var(--font-M);
  text-transform: lowercase;
  margin-top: 0;
  text-align: center;
}
.support-box__activation-date {
  text-align: center;
  margin-bottom: calc(var(--size) * 2);
  font-size: var(--font-S);
  text-transform: lowercase;
  margin-top: 0;
  margin-bottom: 30px;
  color: var(--grey-99);
}

.support-box--active {
  border: 3px solid var(--orange) !important;
}

.support-box__features {
  margin-top: calc(var(--margin) * 5);
  min-height: calc(var(--size) * 22);
}
.support-box__features a {
  border-bottom: 1px dotted var(--orange);
  padding-bottom: 2px;
}

.support-box__price {
  min-height: calc(var(--size) * 8);
  margin-top: calc(var(--margin) * 4);
  text-align: center;
}
.support-box__price--number {
  margin-top: calc(var(--margin) * 0.5);
  color: var(--blue);
  font-size: var(--font-3XL);
  margin: calc(var(--margin) * 4) 0 0 0;
  line-height: 1.1;
  text-align: center;
}

.support-box ul {
  list-style-type: none;
  padding: 0;
  min-height: calc(var(--size) * 9);
}
.support-box li {
  display: flex;
  align-items: center;
  line-height: calc(var(--size) * 2);
  font-size: var(--font-S);
  margin-bottom: calc(var(--margin) * 2);
}
.support-box li i.icon {
  margin-left: var(--size);
  margin-right: var(--size);
  font-size: var(--font-XL);
  color: var(--orange);
}

.support-box__button-wrapper {
  margin-bottom: calc(var(--margin) * 3);
  text-align: center;
  display: flex;
  justify-content: space-between;
  padding: 0 var(--padding);
}
.support-box__button-wrapper a {
  display: inline-flex;
  padding-top: calc(var(--padding) * 0.8);
  color: var(--white);
}

.support-box__contact-info {
  text-align: center;
  display: block;
  margin-top: calc(var(--margin) * 2);
  color: var(--orange);
  font-size: var(--font-L);
}

.support-box__disclaimer {
  color: var(--grey-66);
}
