.frc-captcha {
  max-width: 100% !important;
}
.frc-button {
  margin-top: var(--size) !important;
  margin-bottom: var(--size) !important;
  max-width: max-content;
}
.frc-container {
  align-items: flex-start !important;
}
.frc-content {
  overflow: inherit !important;
}
