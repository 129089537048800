.ace_editor {
  border-radius: calc(var(--size) * 2) 0 0 calc(var(--size) * 2);
}
.ace_editor .ace_content {
  margin-right: calc(-1 * var(--size));
  font-weight: normal;
}
.ace_editor .ace_content * {
  font-family: "Monaco", "Menlo", "Ubuntu Mono", "Droid Sans Mono", "Consolas",
    monospace;
}
.ace_editor .ace_scroller {
  background-color: var(--grey-f7);
}
