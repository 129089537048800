i.icon {
  margin: 0;
  /* Need to reset the backface visibility here as it is overwritten bu semantic ui*/
  backface-visibility: visible;
}
i.icon.black {
  color: var(--grey-33) !important;
}
i.icon.green {
  color: var(--green) !important;
}
i.icon.orange {
  color: var(--orange) !important;
}
i.icon.red {
  color: var(--red) !important;
}
i.icon.icon-large {
  line-height: 1;
  vertical-align: middle;
  font-size: 1.5em;
  margin-top: calc(-0.3 * var(--margin));
}
i.icon.invert-animation {
  animation-direction: reverse;
}
