.detailed-view-modal--content {
  padding: 0;
  min-height: calc(20 * var(--size));
  overflow-x: hidden !important;
}
.detailed-view-modal--content .more-data {
  position: static;
  margin: 0;
  padding: var(--padding) 0 !important;
  overflow: visible;
}

/* Detailed view modal placement overriding semantic rule*/
.modal.fullscreen.detailed-view-modal {
  left: auto !important;
}

/* Three Columns */
.detailed-view-modal--content .columns-3 .column {
  width: 33% !important;
  border-left: var(--border) solid var(--grey-ee);
}
.detailed-view-modal--content .columns-3 .column:nth-child(3n + 1) {
  border-left: 0 solid transparent;
}
.detailed-view-modal--content .columns-3 .column.no-border {
  border-left: 0 solid transparent;
}
.detailed-view-modal--content .columns-3 .column:nth-child(n + 4) {
  margin-top: var(--size);
}
@media screen and (max-width: 1024px) {
  .detailed-view-modal--content .columns-3 .column {
    width: 50% !important;
    border-left: 0 solid transparent;
  }
}

/* Two Columns */
.detailed-view-modal--content .columns-2 .column {
  width: 50% !important;
  border-left: var(--border) solid var(--grey-ee);
}
.detailed-view-modal--content .columns-2 .column:first-child {
  border-left: 0 solid transparent;
}

/* Overwrite td size in detailed view opening in modal */
.detailed-view-modal--content.content td {
  vertical-align: top;
  max-width: calc(var(--size) * 10);
  line-height: calc(var(--size) * 3.5);
  text-overflow: ellipsis;
}
