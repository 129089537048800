body.animating.in.dimmable,
body.dimmed.dimmable {
  overflow: visible;
}

.ui.modals.dimmer {
  background-color: rgba(0, 0, 0, 0.15);
}

/* Inverted Color Dimmer */
.ui.dimmer.inverted {
  background: rgba(var(--black-rgb), 0.2);
}

/* Blurring state for Dimmer */
/* Happens when confirm box opens */
.blurring.dimmed.dimmable > :not(.dimmer) {
  -webkit-filter: none;
  filter: none;
}
.blurring.dimmed.dimmable
  #page-wrapper
  :is(.header-delete-request, .body, .app-header) {
  -webkit-filter: blur(2px) grayscale(0.4);
  filter: blur(2px) grayscale(0.4);
}
