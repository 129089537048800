.button--bordered {
  border: 2px solid var(--orange);
  align-items: center;
  justify-content: center;
}
.button--bordered span {
  min-width: calc(var(--size) * 5);
  flex: 1 1;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  height: calc(var(--size) * 2);
  line-height: calc(var(--size) * 2) !important;
}
.button--bordered:focus {
  color: var(--orange);
}
.button--bordered i.icon {
  margin-right: calc(-1 * var(--margin));
  margin-left: var(--margin);
  color: var(--orange);
  font-size: var(--font-M);
  height: var(--font-M);
  line-height: var(--font-M);
}
.button--bordered:hover {
  background-color: var(--orange);
  color: var(--white);
}
.button--bordered:hover i.icon {
  color: var(--white);
}
.button--bordered:disabled {
  pointer-events: none;
  background: var(--grey-f7) !important;
  border-color: var(--grey-f7) !important;
}
