.menu-wrapper {
  overflow: hidden;
  z-index: 100;
  position: fixed;
  left: 0;
  height: 100%;
  background: var(--white);
  box-shadow: 0 0 calc(var(--size) * 2) 0 rgba(0, 0, 0, 0.1);
}

/* Menu width */
.sidebar-open .menu-wrapper {
  width: var(--sidebar-width--open);
}
#page-wrapper:not(.sidebar-open) .menu-wrapper {
  width: var(--sidebar-width--closed);
}

/* Menu height for both states (open and close) */
.sidebar-open .menu-wrapper nav,
#page-wrapper:not(.sidebar-open) .menu-wrapper nav {
  height: calc(100vh - var(--row-height));
}

.sidebar-open .menu-wrapper nav > ul li span {
  transition: all 100ms ease-in-out;
}

/* Blurring state */
.blurring.dimmed.dimmable #page-wrapper .menu-wrapper {
  -webkit-filter: blur(2px) grayscale(0.4);
  filter: blur(2px) grayscale(0.4);
}

/* Active delete request */
#page-wrapper.with-delete-request .menu-wrapper nav {
  height: calc(100vh - calc(2 * var(--row-height)));
}

/* Inactive menu */
.menu-wrapper--inactive {
  pointer-events: none;
}
.menu-wrapper--inactive:after {
  display: block;
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(var(--black-rgb), 0.15);
}

/* Shrunk menu (Default state) */
.menu-wrapper--shrunk .main-menu--list-wrapper.submenu--open {
  height: calc(var(--size) * 5);
}
.menu-wrapper--shrunk .main-menu--list-wrapper.submenu--open:after {
  display: none;
}

/* Hover-able state menu */
.menu-wrapper--hovered:hover {
  width: var(--sidebar-width--open) !important;
  transition: width 200ms;
}
.menu-wrapper--hovered:hover .main-menu--list-wrapper.submenu--open {
  height: auto;
}
.menu-wrapper--hovered:hover .main-menu--list-wrapper.submenu--open:after {
  display: block;
}
.menu-wrapper--hovered:hover .submenu--level-2 .submenu--open:after {
  height: auto;
  display: block;
}

/* Menu expand toggle */
.menu--expand-toggle {
  height: var(--row-height);
}
.menu--expand-toggle button {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 100%;
  cursor: pointer;
  color: var(--grey-45);
}
.menu--expand-toggle button i {
  margin-right: var(--margin);
}
.menu--expand-toggle.shrunk button {
  justify-content: left;
}
.menu--expand-toggle.shrunk button i {
  margin-left: calc(var(--margin) * 2);
}
