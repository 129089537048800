.ui.dropdown.select-box {
  border-radius: calc(var(--size) * 2);
  background: var(--white);
  width: calc(var(--size) * 20);
  padding: var(--padding) 0 var(--padding) calc(var(--padding) * 0.5);
  border: 0 none;
  color: var(--grey-33);
  line-height: calc(var(--padding) * 2);
  justify-content: center;
  box-shadow: 0 calc(0.5 * var(--size)) calc(1.5 * var(--size)) 0
    rgba(var(--black-rgb), 0.1);
}
.ui.selection.dropdown:focus {
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.1);
}
.ui.dropdown.select-box:hover {
  background-color: var(--grey-f7);
}
.ui.dropdown.select-box div.text {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 90%;
}

.ui.dropdown.select-box .default.text,
.ui.dropdown.select-box .text {
  font-size: var(--font-XS);
  color: var(--grey-33);
  font-size: var(--font-S);
}

.ui.dropdown.select-box > i {
  font-size: var(--font-M);
  float: right;
}

.ui.dropdown.select-box.full {
  width: 100%;
  padding: var(--padding) var(--padding) calc(var(--padding) * 0.5)
    calc(var(--padding) * 2);
  border-radius: calc(2 * var(--size));
}

.ui.dropdown.select-box .visible.menu {
  box-shadow: 0 calc(0.1 * var(--size)) calc(0.5 * var(--size)) 0
    rgba(var(--black-rgb), 0.2);
  border-color: var(--grey-ee) !important;
  width: 100%;
  border-radius: 0 0 calc(1.5 * var(--size)) calc(1.5 * var(--size));
}

/* Upward SelectBox*/
.ui.dropdown.select-box.upward .visible.menu {
  box-shadow: 0 calc(-0.1 * var(--size)) calc(0.5 * var(--size)) 0
    rgba(var(--black-rgb), 0.2);
  border-radius: calc(var(--size) * 1.5) calc(var(--size) * 1.5) 0 0;
}
.ui.dropdown.select-box.upward.active {
  border-radius: 0 0 calc(2 * var(--size)) calc(2 * var(--size)) !important;
}
.ui.dropdown.select-box.upward.active:after {
  opacity: 0;
}
.ui.dropdown.select-box.upward.active.dropdown:hover {
  box-shadow: 0 calc(0.5 * var(--size)) calc(1.5 * var(--size)) 0
    rgba(var(--black-rgb), 0.1);
}

/* SelectBox item */
.ui.selection.dropdown .menu > .item {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: calc(var(--size) * 5);
  max-width: none;
  height: calc(var(--size) * 5);
}
.ui.selection.dropdown .menu > .item:hover {
  background-color: var(--grey-f7);
}

/* Active State (When select box is open)*/
.ui.dropdown.select-box.active {
  background-color: var(--white);
  position: relative;
}

.ui.dropdown.select-box.active,
.ui.dropdown.select-box.active:hover {
  box-shadow: 0px 0px 5px 0px var(--grey-cc);
}
.ui.dropdown.select-box:hover {
  background-color: var(--grey-fc);
  box-shadow: 0 calc(0.5 * var(--size)) calc(1.5 * var(--size)) 0 var(--grey-cc);
}

/* Box Shadow on menu when select box is open */
.ui.dropdown.select-box.active div.menu {
  box-shadow: 0 calc(0.1 * var(--size)) calc(0.5 * var(--size)) 0
    rgba(var(--black-rgb), 0.2);
  border-color: var(--grey-ee) !important;
}

/* The compact SelectBox used to align smaller SelectBox in a wrapper */
/* Example of use case is the MaintenanceWindow in Gardener */
.ui.select-box.select-box--compact {
  min-width: 0;
  box-shadow: 0 0 0 0 transparent !important;
}
.select-box--compact i {
  display: none;
}
.select-box--compact:hover {
  background-color: var(--white) !important;
}
.select-box--compact .text {
  width: 100%;
}
.select-box--compact.text-right * {
  text-align: right !important;
}
