.disconnected-warning {
  position: fixed;
  pointer-events: none;
  right: 0;
  bottom: 0;
  width: calc(var(--size) * 20);
  z-index: 5000;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  right: 50%;
  bottom: auto;
  bottom: 0%;
  transform: translate(50%, 0%);
}
.disconnected__wrapper {
  width: calc(var(--size) * 4);
  height: calc(var(--size) * 4);
  display: inline-block;
  position: relative;
}
.disconnected__icon {
  width: calc(var(--size) * 4);
  height: calc(var(--size) * 4);
  position: absolute;
  top: 0;
  left: 0;
}
.disconnected__hidden {
  fill: var(--blue);
}
.disconnected__background {
  fill-opacity: 0.3;
}
.disconnected__animation {
  animation: connect 1.3s 1s 3000 ease-in-out alternate;
  clip-path: circle(0 at 50% 99%);
}
.disconnected__text {
  color: var(--blue);
  font-size: var(--font-S);
}
