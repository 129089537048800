.striped-table {
  width: 100%;
  margin-bottom: 0;
}
.striped-table > tbody > tr > td {
  padding: 0 var(--padding) !important;
  overflow: hidden;
  white-space: nowrap;
  width: 30%;
}

/* Strip color */
.striped-table > tbody > tr:nth-child(2n) > td {
  background-color: var(--grey-f7);
  border-radius: 0;
}

/* Roundness */
.striped-table > tbody > tr:nth-child(2n) > td:first-child {
  border-radius: var(--size) 0 0 var(--size);
}
.striped-table > tbody > tr:nth-child(2n) > td:last-child {
  border-radius: 0 var(--size) var(--size) 0;
}
.striped-table > tbody > tr:nth-child(2n) > td[colspan="2"] {
  border-radius: var(--size);
}

/* Reverse Strip color */
.striped-table.reverse-strip-order > tbody > tr:nth-child(2n + 1) > td {
  background-color: var(--grey-f7);
  border-radius: 0;
}
.striped-table.reverse-strip-order
  > tbody
  > tr:nth-child(2n + 1)
  > td:first-child {
  border-radius: var(--size) 0 0 var(--size);
}
.striped-table.reverse-strip-order
  > tbody
  > tr:nth-child(2n + 1)
  > td:last-child {
  border-radius: 0 var(--size) var(--size) 0;
}
.striped-table.reverse-strip-order > tbody > tr:nth-child(2n) > td {
  background-color: transparent;
}

.striped-table.no-background > tbody > tr > td {
  background-color: transparent;
}
