.sliding-panel {
  position: fixed;
  width: var(--sliding-window);
  max-width: 70%;
  display: none;
  z-index: 5000;
  top: var(--row-height);
  bottom: 0;
  right: 0;
}
.sliding-panel--knowledge-base-open {
  right: var(--knowledge-base);
}
.sliding-panel:after {
  content: "";
  display: block;
  position: absolute;
  right: calc(-20 * var(--size));
  width: calc(20 * var(--size));
  background: var(--grey-f7);
  top: 0;
  bottom: 0;
}
.sliding-panel--visible {
  display: block;
}

.sliding-panel__layer {
  padding: calc(var(--row-height) * 1.5) calc(var(--padding) * 4)
    calc(var(--row-height) * 0.5) calc(var(--padding) * 4);
  color: var(--grey-33);
  height: calc(100vh - var(--row-height));
  overflow-y: auto;
  overflow-x: hidden;
  box-shadow: calc(-0.5 * var(--size)) 0 var(--size) 0
    rgba(var(--black-rgb), 0.1);
  position: absolute;
  left: 100%;
  top: 0;
  pointer-events: all;
  width: 100%;
  background: var(--white);
  animation: 0.2s ease-in-out forwards slide-from-right;
  transition: margin-left 100ms;
}

/* Layers stacking on top of each other */
.sliding-panel__layer:nth-child(1) {
  z-index: 2;
}
.sliding-panel__layer:nth-child(2) {
  z-index: 4;
}
.sliding-panel__layer:nth-child(3) {
  z-index: 6;
}
.sliding-panel__layer:nth-child(4) {
  z-index: 8;
}
.sliding-panel__layer:nth-child(5) {
  z-index: 10;
}

/* Layers pushing to the left to show the small margin */
.sliding-panel__layer.depth-1 {
  margin-left: calc(-2.5 * var(--margin));
}
.sliding-panel__layer.depth-2 {
  margin-left: calc(-5 * var(--margin));
}
.sliding-panel__layer.depth-3 {
  margin-left: calc(-7.5 * var(--margin));
}
.sliding-panel__layer.depth-4 {
  margin-left: calc(-10 * var(--margin));
}
.sliding-panel__layer.depth-5 {
  margin-left: calc(-12.5 * var(--margin));
}

.sliding-panel__layer:is(h1, h2, h3, h4, h5, h6, p) {
  color: var(--grey-33);
}

/* Layers Title */
.sliding-panel__layer[layer-title] {
  opacity: 1;
  overflow: hidden;
}
.sliding-panel__layer[layer-title]:before {
  content: attr(layer-title);
  font-style: italic;
  display: block;
  bottom: calc(-1 * var(--row-height));
  padding: calc(var(--padding) * 0.5) calc(var(--padding) * 1.5);
  border-radius: 0;
  transform: rotate(-90deg);
  transform-origin: bottom left;
  position: fixed;
  z-index: 30;
  width: 100vh;
  height: calc(var(--size) * 3);
  margin-left: calc(-1.2 * var(--margin));
  font-size: var(--font-XS);
  color: var(--grey-99);
  text-align: right;
}

.sliding-panel__layer .ui.grid > .column:not(.row),
.sliding-panel__layer .ui.grid > .row > .column {
  padding-left: var(--padding);
  padding-right: var(--padding);
}

/* Estimation price modifiers */
.sliding-panel__layer:is(.depth-1, .depth-2, .depth-3, .depth-4, .depth-5)
  .invoice_wrapper {
  margin-left: -10px;
}
.sliding-panel__layer .invoice_wrapper {
  position: fixed;
  width: var(--sliding-window);
  margin-left: calc(-4 * var(--margin));
  margin-top: calc(-2.5 * var(--margin));
  z-index: 20;
}

.sliding-panel__layer .ui.header {
  color: inherit;
  border-bottom: 0 transparent;
}
.sliding-panel__layer .ui.loader {
  z-index: 1;
}
.sliding-panel__layer .column i.warning.circle.icon {
  font-size: var(--font-XS);
}

/* Create... */
.start-point {
  margin-top: calc(-1.5 * var(--row-height));
}
.start-point__header {
  padding: 0 calc(var(--row-height) * 0.5);
  line-height: var(--row-height);
  font-size: var(--font-L);
  margin: calc(-0.75 * var(--row-height)) calc(-4 * var(--margin))
    calc(2 * var(--margin)) calc(-4 * var(--margin));
  background-color: var(--grey-f7);
}
.start-point__autocomplete-wrapper {
  display: flex;
  padding: var(--padding) 0;
  justify-content: center;
  align-items: center;
  gap: var(--margin);
}

.start-point__buttons-wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(calc(var(--size) * 10), 1fr));
  grid-gap: calc(var(--size) * 2);
  margin-top: calc(var(--margin) * 2);
  margin-bottom: calc(var(--margin) * 2);
}

.start-point__button {
  box-shadow: 0 var(--size) calc(2 * var(--size)) 0 rgba(var(--black-rgb), 0.1);
  border-radius: calc(var(--size) * 1.5);
  overflow: hidden;
}
.start-point__button:hover,
.start-point__button.isHovered {
  box-shadow: 0 calc(0.5 * var(--size)) calc(2 * var(--size)) 0
    rgba(var(--black-rgb), 0.2);
}
.start-point__button:hover *,
.start-point__button.isHovered * {
  fill: var(--orange) !important;
  color: var(--orange) !important;
}

.start-point__button__text {
  transition: all 0.2s ease-in-out;
  display: block;
  padding: calc(var(--padding) * 0.5);
  padding-bottom: var(--padding);
  color: var(--grey-66);
  line-height: calc(var(--size) * 1.6);
  display: flex;
  height: calc(var(--size) * 4);
  align-items: center;
  justify-content: center;
  margin: 0 0 calc(var(--margin) * 0.5) 0;
}

.start-point__button__icon {
  justify-content: center;
  padding: calc(2 * var(--padding)) 0 0 0;
  background: var(--white);
  align-items: center;
  display: flex;
  color: var(--grey-66);
  font-size: var(--font-S);
  position: relative;
}
.start-point__button__icon svg {
  height: calc(var(--size) * 4);
  fill: var(--grey-66);
}

.start-point__button__icon i {
  border-radius: calc(3 * var(--size));
  padding: calc(var(--padding) * 2.2);
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Openstack user => Openstack icon*/
.start-point__button__icon i:nth-child(2) {
  position: absolute;
  font-size: var(--font-M);
  top: calc(var(--size) * 1.5);
  right: calc(var(--size) * 1.5);
}

/* Cleura user => Cleura icon*/
.start-point__button__icon svg:nth-child(2) {
  position: absolute;
  font-size: var(--font-M);
  top: calc(var(--size) * 1.5);
  right: calc(var(--size) * 2);
  fill: var(--grey-66);
}
