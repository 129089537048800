.user-settings {
  width: 100%;
}

/* Trigger user settings*/
.user-settings__trigger {
  height: calc(var(--size) * 3);
  width: calc(var(--size) * 4);
  transition: all 0.3s;
}
.user-settings__trigger:hover {
  transform: translateY(calc(-0.2 * var(--size)));
}

.user-settings__window {
  margin-left: calc(-2.5 * var(--size));
  margin-right: calc(-2.5 * var(--size));
}

.user-settings__user {
  padding-left: calc(2.5 * var(--size));
  padding-right: calc(2.5 * var(--size));
}
