.payment {
  min-height: calc(var(--size) * 20);
  margin: 0 calc(var(--size) * 3);
  padding: calc(var(--size) * 3) 0;
}

.payment__method {
  padding: var(--padding) calc(var(--padding) * 2);
  border: 1px solid var(--grey-ee);
  border-radius: calc(1.5 * var(--size));
  margin: 0 0 calc(var(--size) * 2) 0;
  display: grid;
  min-height: calc(var(--size) * 14);
  cursor: pointer;
  grid-template-columns: calc(var(--size) * 10) 1fr;
}
.payment__method:hover {
  box-shadow: 0 0 calc(var(--size) * 2) 0 rgba(0, 0, 0, 0.1);
}
.payment__method--disabled {
  pointer-events: none;
  opacity: 0.3;
}

/* Place icons on top of each other */
.payment__icons i {
  position: absolute;
}
.payment__icons i:nth-child(1) {
  z-index: 3;
}
.payment__icons i:nth-child(2) {
  top: calc(var(--size) * 2.5);
  left: calc(var(--size) * 2.5);
  z-index: 2;
}
/* Provide a background so the visa icon is visible fully */
.payment__icons i:nth-child(2):after {
  position: absolute;
  content: "";
  right: calc(var(--size) * 3);
  bottom: calc(var(--size) * 3);
  width: calc(var(--size) * 4);
  height: calc(var(--size) * 3);
  background: var(--white);
}

.payment__footer {
  grid-column: span 2;
  padding-top: calc(var(--size) * 0.5);
  margin-top: var(--size);
  padding-left: calc(var(--size) * 10);
  position: relative;
  border-top: 1px solid var(--grey-ee);
}
