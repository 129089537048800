.dashboard-widget {
  aspect-ratio: 5/3;
  margin-bottom: 20px;
  margin-left: 10px;
  margin-right: 10px;
  flex: 0 0 auto;
  position: relative;
  float: left;
}
@media screen and (max-width: 800px) {
  .dashboard-widget {
    width: 100%;
  }
}
@media screen and (min-width: 800px) and (max-width: 1200px) {
  .dashboard-widget {
    width: calc(50% - 20px);
  }
}
@media screen and (min-width: 1200px) and (max-width: 1600px) {
  .dashboard-widget {
    width: calc(33.333% - 20px);
  }
}
@media screen and (min-width: 1600px) {
  .dashboard-widget {
    width: calc(25% - 20px);
  }
}

.dashboard-widget .ui.segment {
  position: absolute;
  width: 100%;
  height: 100%;
  background: var(--white);
  border-radius: calc(var(--size) * 2.5);
  box-shadow: 0 calc(0.5 * var(--size)) calc(2 * var(--size)) 0
    rgba(var(--black-rgb), 0.1);
}

/* Header */
.dashboard-widget .ui.segment h2 {
  text-align: center;
  font-size: var(--font-M);
  left: 0;
  top: var(--size);
  z-index: 5;
  width: 100%;
  color: var(--grey-45);
  position: absolute;
}
.dashboard-widget .ui.segment h2 span {
  margin-left: calc(var(--size) * 0.5);
  font-size: var(--font-S);
  vertical-align: 1px;
}

/* The big numbers in the middle */
.dashboard-widget .ui.segment .number {
  position: absolute;
  left: 0;
  font-size: calc(4 * var(--font-M));
  text-align: center;
  margin: auto;
  display: block;
  width: 100%;
  top: 35%;
}
.dashboard-widget .ui.segment .number.small {
  position: relative;
  bottom: auto;
  height: auto;
  margin: calc(var(--margin) * 3) 0 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: calc(2 * var(--font-M));
  line-height: calc(3 * var(--size));
  top: auto;
  flex-direction: column;
}
.dashboard-widget .ui.segment .number.small .medium {
  font-size: var(--font-S);
}
.dashboard-widget .ui.segment .number.small .smaller {
  font-size: var(--font-XS);
}

/* Promotion Widget  */
@media print, screen and (max-width: 800px) {
  .dashboard-widget.promotion {
    width: 100%;
    aspect-ratio: 10 / 7;
  }
}
@media screen and (min-width: 800px) {
  .dashboard-widget.promotion {
    width: calc(100% - 20px);
    height: 270px;
  }
}
.dashboard-widget.promotion .ui.segment {
  border: 4px solid var(--orange);
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.dashboard-widget.promotion h2 {
  font-size: var(--font-3XL) !important;
  color: var(--black) !important;
  position: relative !important;
}
.dashboard-widget.promotion h3 {
  font-size: var(--font-XL) !important;
  color: var(--black);
  position: relative !important;
  margin-top: 10px;
}
.dashboard-widget.promotion p {
  flex: 1;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding-bottom: 30px;
}
@media print, screen and (max-width: 800px) {
  .dashboard-widget.signup-promo {
    width: 100%;
    height: 270px;
  }
}
