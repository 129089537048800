.button--circular {
  background-color: var(--grey-f7);
  border-radius: 50%;
  color: var(--grey--99);
  display: inline-block;
  fill: var(--grey--99);
  height: calc(var(--size) * 3);
  width: calc(var(--size) * 3);
  padding: 0;
  outline: transparent;
  vertical-align: top;
  position: relative;
  overflow: visible;
  transition: none;
}
.button--circular:hover {
  transform: translateY(0);
}

.button--circular__danger {
  background-color: transparent;
  border: solid calc(2 * var(--border)) var(--red);
  color: var(--red);
  margin: 0 0 0 calc(var(--margin) * 0.3);
}
.button--circular__danger:hover {
  background-color: var(--red);
  color: var(--white);
}

.button--circular__active {
  background-color: transparent;
  border: solid calc(2 * var(--border)) var(--green);
  color: var(--green);
  margin: 0 0 0 calc(var(--margin) * 0.3);
}
.button--circular__active:hover {
  background-color: var(--green);
  color: var(--white);
}

.button--circular i.icon {
  width: calc(var(--size) * 1.4);
  margin-right: 0;
  margin-left: 0;
}

.button--circular .button__count-hidden {
  display: none;
}

.button--circular .button__count-visible {
  display: flex;
  background: var(--orange);
  font-size: var(--font-2XS);
  border-radius: calc(var(--size) * 1.5);
  color: var(--white);
  position: absolute;
  top: calc(-0.7 * var(--size));
  right: calc(-0.3 * var(--size));
  min-width: calc(var(--size) * 1.5);
  padding: 0;
  justify-content: center;
  height: calc(var(--size) * 1.5);
  align-items: center;
  letter-spacing: 0;
  padding: calc(var(--padding) * 0.1);
}

/* Overwriting part of global button--disabled class defined in button.css */
.button--circular.button--enabled {
  background-color: var(--white);
  box-shadow: 0 calc(0.3 * var(--size)) var(--size) 0
    rgba(var(--black-rgb), 0.2);
}
.button--circular.button--enabled:hover i.icon {
  color: var(--orange);
}

.button--circular.button--disabled {
  opacity: 1;
  color: var(--grey-cc);
  fill: var(--grey-cc);
  cursor: not-allowed;
  background-color: var(--grey-f7);
  border-color: transparent;
  box-shadow: 0 0 0 0 transparent;
}
.button--circular.button--disabled span {
  display: none;
}

.button--circular.button--over-input {
  border-radius: 0 calc(var(--size) * 3) calc(var(--size) * 3) 0;
  background-color: var(--grey-ee);
  height: calc(var(--size) * 4);
  position: absolute;
  right: 0;
  width: calc(var(--size) * 4);
  border-left: var(--border) solid var(--grey-ee);
}
