.status-icon {
  position: absolute;
  transform: translateX(-40%) translateY(0%);
  box-sizing: content-box;
  display: block;
  border-radius: 50%;
  z-index: 5;
  padding: 0;
  margin-top: var(--margin);
  left: calc(var(--size) * 3);
  width: calc(var(--size) * 1.6);
  height: calc(var(--size) * 1.6);
}
.status-icon i {
  font-size: var(--font-L);
  width: auto;
}

/*The white background beneath the icon */
.status-icon i:after {
  display: block;
  content: "";
  position: absolute;
  background: white;
  width: calc(var(--size) * 1.2);
  height: calc(var(--size) * 1.2);
  top: calc(var(--size) * 0.3);
  left: calc(var(--size) * 0.1);
  border-radius: 50%;
  z-index: -1;
}
