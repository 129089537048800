/* Buttons color versions */
.button--green {
  background-color: var(--green);
  color: var(--white);
}

.button--green:hover {
  background-color: var(--green-dark);
}

.button--orange {
  background-color: var(--orange);
  color: var(--white);
}

.button--orange:hover {
  background-color: var(--orange-dark);
  color: var(--white);
}

.button--orange:disabled {
  background-color: var(--orange);
  color: var(--grey-80);
}

.button--blue {
  background-color: var(--blue);
  color: var(--white);
}

.button--blue:hover {
  background-color: var(--blue-dark);
}

.button--red {
  background-color: var(--red);
  color: var(--white);
}

.button--red:hover {
  background-color: var(--red-dark);
}

.button--white {
  background-color: var(--white);
  color: var(--black);
}

.button--white:hover {
  background-color: var(--grey-ee);
}

/* Buttons with icons */
.button--icon__left {
  padding-left: calc(var(--padding) * 0.8);
  padding-right: calc(var(--padding) * 2);
}

.button--icon__left .icon {
  margin-right: calc(var(--margin) * 1.5);
  align-self: center;
  margin-top: calc(-0.1 * var(--margin));
  font-size: var(--font-L);
}

.button--icon__right {
  padding-left: calc(var(--padding) * 2);
  padding-right: calc(var(--padding) * 0.8);
}

.button--icon__right .icon {
  margin-left: calc(var(--margin) * 1.5);
  align-self: center;
  margin-top: calc(-0.1 * var(--margin));
  font-size: var(--font-L);
  order: 2;
}
