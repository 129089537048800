.ui.card {
  box-shadow: 0 calc(0.5 * var(--size)) calc(2 * var(--size)) 0
    rgba(var(--black-rgb), 0.1);
  border-radius: calc(var(--size) * 2);
}
.ui.card .header {
  height: var(--row-height);
  line-height: var(--row-height);
  padding-left: calc(var(--padding) * 2);
  padding-right: calc(var(--padding) * 2);
  font-size: var(--font-S);
}
.ui.card .content {
  padding: calc(var(--padding) * 1.5) calc(var(--padding) * 2);
}
