.ui.toggle.checkbox label {
  padding-top: calc(var(--padding) * 0.4);
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ui.toggle input ~ label {
  margin-top: var(--margin);
}
.ui.toggle.checkbox label {
  padding-left: 4rem;
}

/* Styling the checkbox as toggle button */
.ui.toggle.checkbox .box:before,
.ui.toggle.checkbox label:before {
  width: calc(var(--size) * 4);
  height: calc(var(--size) * 2.5);
}
.ui.toggle.checkbox .box:after,
.ui.toggle.checkbox label:after {
  width: calc(var(--size) * 2.1);
  height: calc(var(--size) * 2.1);
  top: calc(var(--size) * 0.2);
}
.ui.toggle.checkbox .box:after,
.ui.toggle.checkbox label:after,
.ui.toggle.checkbox .box:before,
.ui.toggle.checkbox label:before {
  border-radius: calc(var(--size) * 2);
}

.ui.toggle.checkbox input ~ .box:after,
.ui.toggle.checkbox input ~ label:after {
  left: calc(var(--size) * 0.1);
}
.ui.toggle.checkbox input:checked ~ .box:after,
.ui.toggle.checkbox input:checked ~ label:after {
  left: calc(var(--size) * 1.8);
}

/* Disabled Style */
.ui.checkbox input[disabled] ~ .box:after,
.ui.checkbox input[disabled] ~ label,
.ui.disabled.checkbox .box:after,
.ui.disabled.checkbox label {
  opacity: 0.7;
  cursor: not-allowed !important;
  content: "1";
}

/* The Checked State */
div.ui.toggle.checkbox input:checked ~ .box:before,
div.ui.toggle.checkbox input:checked ~ label:before,
div.ui.toggle.checkbox input:focus:checked ~ .box:before,
div.ui.toggle.checkbox input:focus:checked ~ label:before {
  background: var(--orange) !important;
}
div.ui.toggle.checkbox.checked input:disabled ~ label:before {
  background: var(--orange) !important;
  opacity: 1;
}

/* The Error State */
div.ui.toggle.checkbox.checked.error input:disabled ~ label:before {
  background: var(--red) !important;
  opacity: 1;
}
div.ui.toggle.checkbox.checked.error input ~ label {
  color: var(--red) !important;
}

/* Style for Checkbox with Multiline Text */
.ui.checkbox.multi-line label {
  white-space: normal;
  padding-top: 0;
}
