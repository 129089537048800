.backdrop {
  position: fixed;
  inset: 0;
  background-color: rgba(var(--black-rgb), 0.15);
  z-index: 500;
  transition: opacity 100ms;
}
.backdrop.hidden {
  pointer-events: none;
  opacity: 0;
}
.backdrop.visible {
  pointer-events: all;
  opacity: 1;
}
