.button--transparent {
  color: var(--grey-33);
  background-color: transparent;
  padding: 0 calc(var(--padding) * 0.8) 0 calc(var(--padding) * 2);
  margin: 0;
  cursor: default;
  text-align: center;
  outline: transparent;
  line-height: calc(var(--size) * 2.6);
  border-radius: 0;
  align-items: center;
}
.button--transparent:hover {
  transform: translateY(0);
}

.button--transparent i.icon {
  font-size: var(--font-2XL);
  margin-left: var(--margin);
  color: var(--orange);
  cursor: pointer;
  border-radius: 50%;
  line-height: calc(var(--size) * 2);
  width: calc(var(--size) * 2);
  height: calc(var(--size) * 2);
  box-shadow: 0 calc(0.3 * var(--size)) calc(0.6 * var(--size)) 0
    rgba(var(--black-rgb), 0.2);
}
