.full-width-select .item {
  max-width: initial !important;
}

.no-scrolling-text .with-scrolling-text:hover :is(div, span),
.no-scrolling-text .item.with-scrolling-text:hover :is(div, span) {
  transform: none;
  transition: none;
}

.no-scrolling-text .select-option-scrolling:hover :is(div, span) {
  margin-left: 0 !important;
}

.grid__detailed-view-content:has(.monitoring-tab) {
  max-height: initial;
}

.nodes-overview-table.simple-table td {
  width: initial;
}

.nodes-overview-table.simple-table td:nth-child(2) {
  width: 27%;
}

.nodes-overview-table td:after {
  content: "▲";
  display: inline-block;
  opacity: 0;
  font-size: 10px;
  line-height: 10px;
  margin-left: 5px;
}
.nodes-overview-table .sort.asc:after {
  opacity: 1;
}

.nodes-overview-table .sort.desc:after {
  content: "▼";
  opacity: 1;
}
