.breadcrumbs {
  display: flex;
  color: var(--grey-45);
  font-size: var(--font-M);
  padding-right: calc(var(--size) * 0.5);
}

.breadcrumbs__divider {
  margin-left: calc(var(--margin) * 0.3);
  margin-right: calc(var(--margin) * 0.3);
  color: var(--grey-cc);
}

.breadcrumbs__section {
  margin: 0;
  padding: 0;
  color: var(--grey-99);
}
.breadcrumbs__section:last-child {
  color: var(--grey-45);
}

.breadcrumbs__link {
  color: var(--grey-45);
  font-weight: 700;
}
.breadcrumbs__link:hover {
  color: var(--grey-45);
}
