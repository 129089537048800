.about-gardener {
  background: var(--orange);
  position: absolute;
  top: 14px;
  right: 240px;
  width: 22px;
  height: 22px;
  overflow: hidden;
  line-height: 22px;
  text-align: center;
  text-wrap: nowrap;
  border-radius: 25px;
  display: flex;
  justify-content: left;
  cursor: pointer;
  transition: width 0.4s;
  color: var(--white);
}
.about-gardener--animated {
  animation: animate-gardener-info 3s ease-in-out forwards;
}
.about-gardener--hoverable:hover {
  width: 350px;
}
.about-gardener__text {
  line-height: calc(2 * var(--size));
}

.readiness--icon {
  height: calc(2.5 * var(--size)) !important;
  width: calc(2.5 * var(--size));
  border-radius: 50%;
  margin-right: 2px;
  border: 1px solid;
  display: inline-block;
  text-align: center;
}
.readiness--icon i {
  padding-top: calc(0.2 * var(--size));
  height: calc(1.5 * var(--size)) !important;
}

.readiness--green {
  border-color: var(--green);
}
.readiness--orange {
  border-color: var(--orange);
}
.readiness--blue {
  border-color: var(--blue);
}
.readiness--red {
  border-color: var(--red);
}
