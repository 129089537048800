/* Shades of black */
.color-pureblack {
  color: var(--black) !important;
}
.color-black {
  color: var(--grey-33) !important;
}
.color-gray {
  color: var(--grey-45) !important;
}
.color-gray-light {
  color: var(--grey-66) !important;
}
.color-disable {
  color: var(--grey-99) !important;
}
.color-gray-mid-light {
  color: var(--grey-cc) !important;
}
.color-light-grey {
  color: var(--grey-ee) !important;
}
.color-white {
  color: var(--white) !important;
}

.color-blue {
  color: var(--blue) !important;
}
.color-up,
.color-active,
.color-green {
  color: var(--green) !important;
}
.color-down,
.color-stopped,
.color-red {
  color: var(--red) !important;
}
.color-condition_down,
.color-paused,
.color-rescued,
.color-orange {
  color: var(--orange) !important;
}

/* Fill colors for SVGs */
.fill-orange {
  fill: var(--orange) !important;
}
.fill-white {
  fill: var(--white);
}
