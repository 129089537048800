@font-face {
  font-family: "Roboto";
  src:
    local("Roboto"),
    url("../../fonts/Roboto-Medium.woff2") format("woff2"),
    url("../../fonts/Roboto-Medium.woff") format("woff"),
    url("../../fonts/Roboto-Medium.ttf") format("truetype"),
    url("../../fonts/Roboto-Medium.otf") format("opentype"),
    url("../../fonts/Roboto-Medium.eot") format("embedded-opentype");
  font-weight: normal;
}

:root {
  --font-family: "Roboto", sans-serif;
  --font-3XL: 24px;
  --font-2XL: 22px;
  --font-XL: 20px;
  --font-L: 18px;
  --font-M: 16px;
  --font-S: 14px;
  --font-XS: 12px;
  --font-2XS: 10px;
  --font-3XS: 8px;

  --font-weight-normal: 500;
  --font-weight-bold: 700;
}

.font-3XL {
  font-size: var(--font-3XL) !important;
}
.font-2XL {
  font-size: var(--font-2XL) !important;
}
.font-double-XL {
  font-size: calc(2 * var(--font-XL)) !important;
}
.font-XL {
  font-size: var(--font-XL) !important;
}
.font-double-L {
  font-size: calc(2 * var(--font-L)) !important;
}
.font-L {
  font-size: var(--font-L) !important;
}
.font-double-M {
  font-size: calc(2 * var(--font-M)) !important;
}
.font-M {
  font-size: var(--font-M) !important;
}
.font-S {
  font-size: var(--font-S) !important;
}
.font-XS {
  font-size: var(--font-XS) !important;
}
.font-2XS {
  font-size: var(--font-2XS) !important;
}
.font-3XS {
  font-size: var(--font-3XS) !important;
}
