#page-wrapper {
  height: 100%;
  transition: margin-right 200ms ease-in-out;
  margin-left: calc(var(--size) * 6);
  padding-top: var(--row-height);
}

/* Status classes for triggering elements */
.page-wrapper--knowledge-base {
  margin-right: var(--knowledge-base);
}
.page-wrapper--logged-out {
  margin-left: 0 !important;
}

#page-wrapper > .contents {
  transition: all 200ms ease-in-out;
  transform: translate3d(0, 0, 0);
  margin: 0;
  padding: 0;
  max-width: 100%;
}

/* Sidebar status */
@media print, screen and (max-width: 800px) {
  #page-wrapper.sidebar-open > .contents {
    transform: translate3d(var(--sidebar-width--open), 0, 0);
  }
}
@media print, screen and (min-width: 800px) {
  #page-wrapper.sidebar-open {
    margin-left: var(--sidebar-width--open);
  }
}

/* Page Content */
.page-content {
  padding: calc(var(--padding) * 3);
  padding-bottom: calc(var(--padding) * 2);
  margin: 0;
  width: 100%;
  min-height: calc(100vh - var(--row-height) - var(--row-height));
  flex-direction: column;
}
@media print, screen and (max-width: 1200px) {
  .page-content .break-medium-size > div {
    width: 100%;
  }
}
@media print, screen and (min-width: 1200px) {
  .page-content .break-medium-size > div {
    width: 1200px;
  }
}

/* Top Section -- Multi action section */
.page-content .top-section {
  position: sticky;
  top: calc(var(--row-height) * 2);
  z-index: 240;
  background: var(--white);
  padding-top: calc(var(--padding) * 0.5);
  width: 100%;
}
.page-content .top-section:before,
.page-content .top-section:after {
  background: var(--white);
  content: "";
  position: absolute;
  bottom: 0;
  width: calc(var(--size) * 3);
  top: 0;
}
.page-content .top-section:before {
  left: calc(-3 * var(--size));
}
.page-content .top-section:after {
  right: calc(-3 * var(--size));
}

.page-content .top-section .pos_right input {
  width: calc(var(--size) * 30);
}
@media screen and (max-width: 800px) {
  .page-content .top-section .pos_right input {
    width: calc(var(--size) * 20);
  }
}
