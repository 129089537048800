.ui.checkbox.simple-checkbox {
  min-height: calc(var(--size) * 2);
  margin-right: var(--margin);
}

.ui.checkbox.simple-checkbox label {
  margin-left: 0;
  padding-left: calc(var(--padding) * 3);
  display: inline-block;

  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
  height: 30px;
  padding-top: 5px;
}
.ui.checkbox.simple-checkbox label:before {
  background-color: var(--grey-ee);
  width: calc(var(--size) * 2);
  height: calc(var(--size) * 2);
  top: calc(0.3 * var(--size));
  border-radius: 50%;
  border-color: transparent;
}

.ui.checkbox.simple-checkbox input:focus ~ label:before {
  background-color: var(--grey-ee);
  border-color: transparent;
}
.ui.checkbox.simple-checkbox input:checked ~ label:before {
  background-color: var(--orange);
  border-color: transparent;
}
.ui.checkbox.simple-checkbox input:checked ~ label:after {
  color: var(--white);
  top: calc(0.5 * var(--size));
  left: calc(var(--size) * 0.15);
}
.ui.checkbox.simple-checkbox input:disabled ~ label {
  opacity: 0.5;
}
