/* By default in 'semantic ui' the loader inside a dimmer is colored white.
  We need to skip that because we can have modals that have dimmer on the background and the loading inside shall be as usual
  Example of this can be the Charts in Server Details when loaded inside View Details component
*/
.ui.loader.loader-in-modal {
  color: var(--grey-33) !important;
}
.ui.loader.loader-in-modal:before {
  border-color: var(--grey-ee) !important;
}
.ui.loader.loader-in-modal:after {
  border-top-color: var(--grey-99) !important;
}

.loader-wrapper {
  width: 100%;
  position: relative;
  min-height: calc(var(--size) * 4);
}
.loader-wrapper.double-height {
  min-height: calc(var(--size) * 8);
}
.loader-wrapper.align-top > div {
  top: 0 !important;
}
.loader-wrapper.align-left > div {
  left: 0;
  transform: translateX(0);
}

/* Fixed loading on top of the */
.loading-fixed {
  top: calc(var(--size) * 2.5) !important;
  color: var(--grey-99);
  padding: 0 0 0 calc(var(--padding) * 1.5) !important;
  white-space: nowrap;
}
.loading-fixed:before,
.loading-fixed:after {
  left: 0 !important;
}

/* Loading in one line*/
.ui.text.loader.one-liner {
  padding: 0 0 0 calc(var(--padding) * 1.5) !important;
  white-space: nowrap;
  color: var(--grey-99);
}
.ui.text.loader.one-liner:before,
.ui.text.loader.one-liner:after {
  left: 0;
}
.ui.text.loader.one-liner.small {
  line-height: 2em;
}
.ui.text.loader.one-liner.justify-to-right {
  left: auto;
  right: 0;
  transform: translate(calc(-1 * var(--size)), -50%);
}
.ui.text.loader.one-liner.justify-to-left {
  left: 0;
  transform: translate(var(--size), -50%);
}
.ui.text.loader.one-liner.fix-spinner:after {
  border-top-color: var(--grey-cc);
}
.ui.text.loader.one-liner.float-left {
  left: 0;
  transform: translateX(calc(var(--size) * 2)) translateY(-50%);
}
