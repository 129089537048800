.worker-labels-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.worker-labels-list .label {
  margin: 0.5rem 0.5rem 0.5rem 0;
  padding: 0.5rem 1rem;
  background-color: var(--grey-ee);
  border-radius: calc(var(--size) * 2);
  display: inline-block;
}

.worker-labels-list .label .icon {
  margin-left: 1rem;
}
