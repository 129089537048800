.simple-table {
  width: 100%;
}
.simple-table--medium {
  min-width: 300px;
}
.simple-table tbody {
  background-color: transparent;
}
.simple-table tbody tr {
  background-color: transparent !important;
  border: var(--border) solid transparent !important;
}
.simple-table tbody tr td {
  border: var(--border) solid transparent !important;
  font-weight: normal;
  margin: 0 0 !important;
}
.simple-table tbody tr td.text-right {
  text-align: right;
}
.simple-table tbody tr td:first-child {
  min-width: 30% !important;
}

.simple-table--space-between td:nth-child(2) {
  text-align: right;
}

@media screen and (max-width: 40em) {
  .simple-table tr td {
    display: inline-table;
    max-width: none !important;
    min-width: calc(var(--size) * 10);
  }
}
