.breadcrumbs-table {
  border: 0 solid transparent !important;
}
.breadcrumbs-table tbody tr td {
  background-color: transparent;
  padding: calc(var(--size) * 0.5) var(--size);
  border: 0 transparent !important;
  border-bottom: var(--border) solid var(--grey-ee) !important;
}
.breadcrumbs-table tbody tr td:nth-child(2) {
  padding-left: 10px !important;
}
.breadcrumbs-table tbody tr td {
  border-bottom: var(--border) solid var(--grey-ee) !important;
}
.breadcrumbs-table tbody tr:last-child td {
  border-bottom: var(--border) solid transparent !important;
}
