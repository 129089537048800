.progress-percent-circle {
  margin-top: 5px;
  --circle-size: calc(var(--size) * 2.8);
  position: relative;
  display: inline-block;
  width: var(--circle-size);
  height: var(--circle-size);
  border-radius: 50%;
  overflow: hidden;
  transform: translateY(calc(-0.3 * var(--size)));
}
.progress-percent-circle__text {
  position: absolute;
  left: 50%;
  top: 50%;
  display: block;
  width: var(--circle-size);
  height: var(--circle-size);
  line-height: var(--circle-size);
  margin-left: calc(-0.5 * var(--circle-size));
  margin-top: calc(-0.5 * var(--circle-size));
  text-align: center;
  z-index: 1;
  font-size: var(--font-2XS);
}
.progress-percent-circle__cluster {
  position: absolute;
  width: var(--circle-size);
  height: var(--circle-size);
}
