:root {
  /* size */
  --size: 10px;
  --margin: 10px;
  --padding: 10px;
  --button-height: 35px;
  --border: 1px;

  /* knowledge base */
  --knowledge-base: 300px;

  /* sidebar */
  --sidebar-width--open: 260px;
  --sidebar-width--closed: 60px;

  /* default height for rows*/
  --row-height: 50px;

  /* create/modify slider */
  --sliding-window: 620px;

  /* feature highlight (getting started) */
  --feature-highlight-tip-size: 500px;

  /* quick view */
  --quick-view-item: 26px;

  /* screens */
  /* Screen sizes as vars are not supported in media query declarations yet */
  /* @media print, screen and (max-width: var(--something)) not supported */
}
