/* Detailed View Close */
.detailed-view__close {
  position: absolute;
  left: 0;
  right: 0;
  top: calc(4 * var(--size));
  margin-top: calc(-5 * var(--margin));
  height: calc(var(--size) * 6);
  z-index: -1;
  display: block !important;
  padding: 0 !important;
}

.more-data {
  cursor: default;
  min-height: calc(var(--size) * 3);
  overflow-y: auto;
  text-align: left;
  overflow-x: hidden;
}

.more-data--padding {
  padding: calc(var(--padding) * 3) !important;
  padding-top: calc(var(--padding) * 2.5) !important;
}

/* The shadow on top of the detailed view */
.more-data:after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: calc(-1 * var(--size));
  height: var(--size);
  box-shadow: 0 calc(0.7 * var(--size)) calc(0.7 * var(--size))
    calc(-0.2 * var(--size)) rgba(var(--black-rgb), 0.05);
}

/* Three Columns */
.more-data .columns-3 {
  padding-bottom: var(--padding);
}

.more-data .columns-3 .column {
  width: 33% !important;
  border-left: var(--border) solid var(--grey-ee);
}

.more-data .columns-3 .column:nth-child(3n + 1) {
  border-left: 0 solid transparent;
}

.more-data .columns-3 .column.no-border {
  border-left: 0 solid transparent;
}

.more-data .columns-3 .column:nth-child(n + 4) {
  margin-top: 10px;
}

/* Two Columns */
.more-data .columns-2 {
  padding-bottom: var(--padding);
}

.more-data .columns-2 .column {
  width: 50% !important;
  border-left: var(--border) solid var(--grey-ee);
}

.more-data .columns-2 .column:first-child {
  border-left: 0 solid transparent;
}

/* Base style for TDs inside the detailed view */
/* These rules can be extended by react-table styles (e.g. The addresses list in servers detailed view) */
.more-data td {
  vertical-align: top;
  max-width: calc(var(--size) * 10);
  overflow: hidden;
  height: calc(var(--size) * 3.5);
  line-height: calc(var(--size) * 3.5);
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* Responsive rules */
@media screen and (max-width: 1024px) {
  .more-data .columns-3 .column {
    width: 50% !important;
    border-left: 0 solid transparent;
  }

  .more-data .columns-3 .column:nth-child(2) {
    border-left: var(--border) solid var(--grey-ee);
  }

  .more-data .columns-3 .column:nth-child(3) {
    width: 100% !important;
    display: block;
  }

  .more-data .columns-3 .column:nth-child(3) > *:nth-child(1) {
    width: calc(50% - 1rem);
    float: left;
  }

  .more-data .columns-3 .column:nth-child(3) > *:nth-child(2) {
    width: calc(50% - 1rem);
    float: right;
  }

  .more-data .columns-3 .column:nth-child(3) table:nth-child(2) {
    margin-top: 0;
  }
}
