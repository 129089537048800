.label {
  font-weight: normal;
}
.label.multi-select__label {
  position: relative;
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: calc(3 * var(--size));
  overflow: hidden;
  height: calc(3 * var(--size));
  background: var(--white);
  border: 1px solid var(--grey-ee);
  border-radius: var(--size);
  font-size: var(--font-S);
  padding: 0 calc(2 * var(--size)) 0 var(--size);
  box-shadow: 0 calc(0.3 * var(--size)) calc(0.6 * var(--size)) 0
    rgba(var(--black-rgb), 0.1);
}
.multi-select__icon {
  position: absolute;
  right: calc(0.5 * var(--size));
}
