.image-select {
  width: 100%;
}
.image-select--open {
  display: block;
}
.image-select--closed {
  display: none;
}
.image-select--input input {
  border-radius: calc(var(--size) * 1.5) calc(var(--size) * 1.5) 0 0 !important;
}

.image-select--dropdown {
  height: calc(var(--size) * 21);
  overflow: hidden;
  z-index: 8;
  position: absolute;
  width: 100%;
  background: white;
  box-shadow: 0 calc(-0.2 * var(--size)) calc(1.5 * var(--size)) 0
    rgba(var(--black-rgb), 0.1);
  border-radius: 0 0 calc(var(--size) * 1.5) calc(var(--size) * 1.5);
}

.image-select--item {
  height: calc(var(--size) * 5);
  line-height: calc(var(--size) * 5);
  padding: 0 0 0 var(--padding);
  display: flex;
  cursor: pointer;
}
.image-select--item:hover {
  background-color: var(--grey-f7);
}
.image-select--item:active {
  background-color: transparent;
}

.image-select--text {
  flex: 1;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
  white-space: nowrap;
}

.image-select--back span {
  flex: 1;
  font-weight: bold;
}

.image-select--level1,
.image-select--level2 {
  position: absolute;
  width: 100%;
  background: var(--white);
  max-height: calc(var(--size) * 20);
  overflow: auto;
  transition: left 0.2s ease-in-out;
  left: -110%;
}
.image-select--level2 {
  left: 100%;
}
.image-select__visible {
  left: 0;
}
.image-select__not-found {
  opacity: 0.5;
  cursor: not-allowed;
}
