.main-menu {
  height: calc(100% - calc(var(--size) * 5));
  font-size: var(--font-S);
  overflow: auto;
  position: relative;
  border-bottom: var(--border) solid var(--grey-ee);
  transition: width 200ms;
}

/* Kills off the shadow casting out of menu item*/
.main-menu > .main-menu--list-wrapper {
  overflow: hidden;
}
.main-menu--list-wrapper:last-child {
  margin-top: auto;
}

.main-menu__item.mainmenu-option-link {
  height: var(--row-height);
}
.main-menu__item svg {
  display: inline-flex;
  height: calc(var(--size) * 5);
  width: calc(var(--size) * 2.4);
  margin-left: calc(var(--size) * 1.8);
  margin-right: calc(var(--size) * 1.8);
  fill: var(--grey-66);
}

/* Menu icons */
/* has to include .icon to override semantic rules */
.menu-item__icon.icon {
  color: var(--grey-66);
  width: var(--sidebar-width--closed);
  padding: 0;
  margin: 0;
  font-size: var(--font-XL);
  height: 50px;
}

/* Menu chevrons (up/down arrows) */
.menu-item__chevron.down {
  float: right;
  font-size: var(--font-XS);
  width: calc(var(--size) * 3);
  color: var(--grey-99);
}
.menu-item__chevron.up {
  float: right;
  margin-right: 0;
  font-size: var(--font-XS);
  width: calc(var(--size) * 3);
}

/* Menu item */
.menu-item {
  font-size: var(--font-S);
  display: block;
  white-space: nowrap;
  width: 100%;
  height: var(--row-height);
  line-height: var(--row-height);
  padding: 0;
  text-align: left;
  color: var(--grey-33);
  outline: none;
  cursor: pointer;
  position: relative;
}
.menu-item__label {
  display: inline-block;
  color: var(--grey-45);
  text-indent: calc(var(--size) * 0.8);
  vertical-align: top;
}
.menu-item--has-subtitle {
  line-height: calc(var(--row-height) - 0.5 * var(--margin));
}
.menu-item__subtitle {
  position: absolute;
  color: var(--grey-45);
  left: 68px;
  top: 32px;
  font-size: var(--font-2XS);
  line-height: 1;
}
.menu-item:hover {
  background-color: var(--grey-ee);
}
.menu-item:hover > .menu-item__label,
.menu-item:hover > .menu-item__subtitle {
  color: var(--grey-45);
}

/* Active state on menu item */
.menu-item--active * {
  color: var(--white) !important;
  fill: var(--white) !important;
}
.menu-item--active,
.menu-item--active:hover {
  background: var(--orange) !important;
}

/* Submenu */
.main-menu__submenu {
  background: var(--grey-fc);
  max-height: 0;
  overflow: hidden;
}
/* Shadow on submenu */
.submenu--open:after {
  display: block;
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: calc(-1 * var(--size));
  box-shadow: 0 calc(-0.4 * var(--size)) calc(var(--size) * 0.8) 0
    rgba(var(--black-rgb), 0.05);
  z-index: 50;
  height: var(--size);
  pointer-events: none;
}
/* Submenu toggle button */
.submenu__toggler {
  font-size: var(--font-S);
  white-space: nowrap;
  width: 100%;
  height: var(--row-height);
  line-height: var(--row-height);
  padding: 0;
  text-align: left;
  color: var(--grey-33);
  cursor: pointer;
}
.submenu__toggler__label {
  display: inline-block;
  color: var(--grey-45);
  text-indent: calc(var(--size) * 0.8);
  vertical-align: top;
}
.submenu__toggler:active,
.submenu__toggler:focus {
  outline: none;
}
.submenu__toggler:hover {
  background-color: var(--grey-ee);
}
.submenu--open .submenu > .submenu__toggler {
  background-color: var(--white);
  box-shadow: 0 0 var(--size) 0 rgba(var(--black-rgb), 0.1);
}
.submenu--open > .main-menu__item > .submenu__toggler {
  background-color: var(--white);
  box-shadow: 0 0 var(--size) 0 rgba(var(--black-rgb), 0.1);
}
.submenu--open > .main-menu__item > .submenu__toggler * {
  color: var(--orange);
  fill: var(--orange);
}

/* Opened Submenu styles */
.submenu--open {
  position: relative;
}
.submenu--open > .submenu--level-1,
.submenu--open > .submenu--level-2 {
  width: 100%;
  max-height: calc(var(--size) * 80);
}
.submenu--open > .submenu--level-2 .menu-item__label {
  margin-left: var(--margin);
}
.submenu--open > .submenu--level-2 .menu-item:hover {
  background-color: var(--grey-ee);
}
.submenu--level-2 .submenu--open:after {
  display: none;
}
